import axios from 'axios';
import {aws} from './aws.config';

/**
export const aws = require('aws-sdk');
aws.config.update({
  region: 'region',
  accessKeyId: 'accessKeyId',
  secretAccessKey: 'secretAccessKey'
});
 */

const S3_BUCKET = 'doyoautocar-img';
const s3 = new aws.S3();

export const getS3Object = (path, fallback) => {
  const params = {
    Bucket: S3_BUCKET, 
    Key: path
  };
  /*
  (err, data) => {
    if (err){
      console.log('AWS:S3:Error',err, err.stack);
    }else{
      console.log('AWS:S3:Success',data); 
    }
  }
  */
  return s3.getObject(params, fallback).promise();
};

const uploadS3Object = (path, data, contentType) => {
  const params = {
    Body:  new Buffer(data.replace(/^data:image\/\w+;base64,/, ""),'base64'),
    Bucket: S3_BUCKET, 
    Key: path,
    ACL: 'public-read',
    ContentType: contentType,
    ContentEncoding: 'base64',
  };
  return s3.putObject(params).promise();
};

export const uploadImageBase64 = (path, imageBase64) => {
  const splitImgStr = imageBase64.split(',');
  const splitData = splitImgStr[0].split(':');
  const splitContent = splitData[1].split(';');

  return uploadS3Object(path, imageBase64, splitContent[0]);
};

export const getUrlImage = (path) => {
  const params = {
    Bucket: S3_BUCKET, 
    Key: path,
  };
  return s3.getSignedUrlPromise('getObject', params).then(url => {
    return Promise.resolve(url.split('?')[0]);
  });
};

export const testAxios = () => {
  axios.get(`https://jsonplaceholder.typicode.com/users`)
      .then(res => {
        console.log('AXIOS:TEST', res.data);
      });
};