import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import HomeIcon from '@material-ui/icons/Home';
import MailIcon from '@material-ui/icons/Mail';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import EmojiTransportationSharpIcon from '@material-ui/icons/EmojiTransportationSharp';
import useStylesCommon from '../common.styles';

import { PAGE_TITLE } from '../common-utils';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

export const SwipeableTemporaryDrawer = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Divider />
      <List>
        <ListItem button component="a" href="/car">
          <ListItemIcon><DirectionsCarIcon /></ListItemIcon>
          <ListItemText primary={PAGE_TITLE.ALL_CAL} />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/contact">
          <ListItemIcon><MailIcon /></ListItemIcon>
          <ListItemText primary={PAGE_TITLE.CONTACT} />
        </ListItem>
        <Divider />
      </List>
    </div>
  );

  const anchor = 'left';

  return (
    <div>
      <React.Fragment key={anchor}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer(anchor, true)}
        >
          <MenuIcon />
        </IconButton>
        {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
        <SwipeableDrawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          onOpen={toggleDrawer(anchor, true)}
        >
          {list(anchor)}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}

export const DrawerConponents = (props) => {
  const { window } = props;
  const classesCommon = useStylesCommon();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <>
      <div
        key='drawer-components'
        className={classesCommon.toolbar}
        style={{ backgroundColor: theme.palette.primary.main }}
      />
      <Divider />
      <List>
        <ListItem button component="a" href="/">
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary={PAGE_TITLE.HOME} />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/car">
          <ListItemIcon><DirectionsCarIcon /></ListItemIcon>
          <ListItemText primary={PAGE_TITLE.ALL_CAL} />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/sell-car">
          <ListItemIcon><EmojiTransportationSharpIcon /></ListItemIcon>
          <ListItemText primary={PAGE_TITLE.CAR_SELL} />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/contact">
          <ListItemIcon><MailIcon /></ListItemIcon>
          <ListItemText primary={PAGE_TITLE.CONTACT} />
        </ListItem>
        <Divider />
      </List>
    </>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classesCommon.root}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className={classesCommon.menuButton}
      >
        <MenuIcon />
      </IconButton>
      <nav className={classesCommon.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classesCommon.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}

          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classesCommon.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

// export default SwipeableTemporaryDrawer;
// export default DrawerConponents;
