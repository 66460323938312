import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Image from 'material-ui-image';
import Box from '@material-ui/core/Box';
import CallEnd from '@material-ui/icons/CallEnd';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import Carousel, { Modal, ModalGateway } from 'react-images';

import useStylesCommon from '../../components/common.styles';
import GoogleMapComponents from '../../components/google-map/google-map.components';
import FacebookPageComponents from '../../components/facebook-page/facebook-page.components';
import FacebookIcon from '../../components/custom-icons/facebook.components';
import LineIcon from '../../components/custom-icons/line.components';

import { ContactInfo } from '../../components/contact-info/contact.info.utils';
// https://www.doyoautocar.com/wp-content/uploads/2018/09/IMG_0049.jpg

const mapImageUrl = 'https://doyoautocar-img.s3-ap-southeast-1.amazonaws.com/info/map_2.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mousePointer: {
    cursor: 'pointer'
  },
  centerCropped: {
    // width: '100px',
    // height: '100px',
    objectFit: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  contactTitle: {
    fontSize: 'xx-large'
  },
  contactHead: {
    fontWeight: "bold"
  },
  googleMap: {
    height: '500px',
  },
  facebook: {
    maxWidth: '500px'
  },
  info: {
    maxWidth: '500px'
  },
}));

const ImageModal = ({ toggleModal, imgState }) => {
  return (<ModalGateway>
    {imgState.modalIsOpen ? (
      <Modal onClose={toggleModal}>
        <Carousel
          views={[{
            source: mapImageUrl
          }]}
          currentIndex={imgState.selectIndex}
        />
      </Modal>
    ) : null}
  </ModalGateway>);
};

const ContractPointComponent = ({ classes, toggleModal, imgState }) => (<Grid item xs={12}>
  <Paper className={classes.paper}>
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      spacing={2}
    >
      <Grid className={classes.mousePointer} item xs={6} md={4} lg={2} onClick={toggleModal}>
        <LocationOnIcon />
        <Box>{ContactInfo.locationTxt}</Box>
      </Grid>
      <Grid item xs={6} md={4} lg={2}>
        <EmailIcon />
        <Box>{ContactInfo.email}</Box>
      </Grid>
      <Grid item xs={6} md={4} lg={2}>
        <CallEnd />
        <Box>{ContactInfo.phone}</Box>
      </Grid>
      <Grid item xs={6} md={4} lg={2}>
        <FacebookIcon url={'https://www.facebook.com/' + ContactInfo.facebook} />
        <Box>{ContactInfo.facebook}</Box>
      </Grid>
      <Grid item xs={6} md={4} lg={2}>
        <LineIcon url={'https://lin.ee/em6y0MI'} />
        <Box>{ContactInfo.line}</Box>
      </Grid>
    </Grid>
  </Paper>
  <ImageModal toggleModal={toggleModal} imgState={imgState} />
</Grid>);

const ContractGoogleMapComponents = ({ classes }) => (<Grid item xs={12}>
  <Paper className={clsx(classes.paper, classes.googleMap)}>
    <GoogleMapComponents />
  </Paper>
</Grid>);

const ContractImageMap = ({ classes, toggleModal, imgState }) => (<Grid item xs={12}>
  <Paper className={clsx(classes.paper, classes.mousePointer)}>
    <Image className={classes.centerCropped} src={mapImageUrl} onClick={toggleModal} />
  </Paper>
  <ImageModal toggleModal={toggleModal} imgState={imgState} />
</Grid>);

const ContractInfoAndFB = ({ classes }) => {
  const contractContent = [
    {
      title: 'ที่อยู่',
      content: '73/105 ม.5 ต.ศาลายา อ.พุทธณฑล จ.นครปฐม 73170'
    },
    {
      title: 'โทร.',
      content: "089-6353614 (สู)"
    },
    {
      title: '',// โทร.
      content: "085-6149671 (วิ)"
    },
    {
      title: '',// โทร.
      content: "092-1143319 (ท๊อป)"
    },
    {
      title: '',// โทร.
      content: "082-7819543 (โย่)"
    },
    {
      title: 'แฟ็กซ์',
      content: '034-100307'
    },
    {
      title: 'อีเมล์',
      content: 'yoyo13494@gmail.com'
    },
    {
      title: 'เปิดทำการทุกวัน',
      content: '08.30 – 19.00 น.'
    }
  ];
  const dataLen = contractContent.length;

  return (<Grid
    container
    direction="row"
    justify="space-between"
    alignItems="flex-start"
  >
    <Grid
      className={classes.info}
      item
      xs={12} sm={6} md={7}>
      <Paper className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={12} className={clsx(classes.contactTitle, classes.contactHead)}>..โดโย่ออโต้คาร์..</Grid>
          {contractContent.map(({ title, content }, index) => {
            const key = index + 1;

            return [
              <Grid key={key} item xs={4}><Box textAlign="right" fontWeight="fontWeightBold" fontSize="16px">{title}</Box></Grid>,
              <Grid key={key + dataLen} item xs={8}><Box textAlign="left">{content}</Box></Grid>,
            ];
          })}
        </Grid>
      </Paper>
    </Grid>
    <Grid className={classes.facebook} item xs={12} sm={6} md={5}>
      <Paper className={classes.paper}><FacebookPageComponents /></Paper>
    </Grid>
  </Grid>);
};

const ContactPage = (props) => {
  const classesCommon = useStylesCommon();
  const classes = useStyles();

  const [imgState, setImgState] = React.useState({
    modalIsOpen: false,
    selectIndex: 0
  });

  const toggleModal = () => {
    // setModalIsOpen(!modalIsOpen);
    setImgState({
      ...imgState,
      modalIsOpen: !imgState.modalIsOpen
    });
  }

  return (<div className={clsx(classesCommon.appBar, classes.root)}>
    <Grid container spacing={3}>
      <ContractPointComponent
        classes={classes}
        toggleModal={toggleModal}
        imgState={imgState}
      />
      <ContractGoogleMapComponents classes={classes} />
      <ContractInfoAndFB classes={classes} />
      <ContractImageMap classes={classes} toggleModal={toggleModal} imgState={imgState} />
    </Grid>
  </div >);
};

export default ContactPage;