import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import SellCarForm from '../../components/car-sell-form/car-sell-form.component';
import useStylesCommon from '../../components/common.styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(3),
    },
  },
}));

const RequestCarSellPage = () => {
  const classes = useStyles();
  const classesCommon = useStylesCommon();

  const [formData, setFormData] = React.useState({
    open: false,
    name: '',
    phone: '',
    line: '',
    facebook: '',
    priceLow: 0,
    priceHight: 0,
    detail: '',
    maxDetailsLength: 2048 - 700, // url + contract page
  });

  return (
    <div className={clsx(classesCommon.appBar, classes.root)}>
      <SellCarForm formData={{ ...formData }} setFormData={setFormData} />
    </div>
  );
};

export default RequestCarSellPage;