import React, { Component } from 'react';
import {Switch,Route} from 'react-router-dom';

import './App.css';
import HomeNevigatePage from './pages/home/home.nevigate.pages';

import RequestCarSellPage from './pages/request-car-sell/request-car-sell.page';
import ContactPage from './pages/contact/contact.components';
import CarsBrandPage from './pages/cars-brand/cars-brand.pages';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import SearchAppBar from './components/app-bar/app-bar.component';
import useStyles from './components/common.styles';
import { getTitle } from './components/common-utils';
import CarsNevigatePage from './pages/car-nevigate/cars.nevigate.pages';

const adminColor = {
  primary: '#FF5722',
  secondary: '#FBC02D',
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: adminColor.primary
    },
    secondary: {
        main: adminColor.secondary,
    },
  },
});

function ResponsiveDrawer(props) {
  const classes = useStyles();

  const pageTitle = getTitle(window.location.pathname);
  let carId;
  
  // find title page support car id
  const sendCarId = pageTitle.match('car_id:');
  if (sendCarId) {
    carId = pageTitle.replace('car_id:', '');
  }

  const WrapStyle = (RouteSelect, props) => {
    return (
      <Box className={classes.root}>
      <CssBaseline />
      <SearchAppBar pageTitle={pageTitle} carId={carId} sendCarId={sendCarId} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <RouteSelect {...props} />
      </main>
    </Box>
    );
  };

  return (
    <Switch>
        <Route exact path='/contact'
          render={(props) => WrapStyle(ContactPage, {...props})} />
        <Route exact path='/sell-car'
          render={(props) => WrapStyle(RequestCarSellPage, {...props})} />
        <Route exact path='/brand/cars/:brand'
          render={(props) => WrapStyle(CarsBrandPage, {...props})} />
        <Route path='/car'
          render={(props) => WrapStyle(CarsNevigatePage, {...props})} />

        <Route path='/' render={(props) => {
          return (<Route component={HomeNevigatePage} />);
        }} />


        {/* <Route path='/' component={HomePage} /> */}
        
      </Switch>
  );
}

class App extends Component {
  constructor() {
    super();

    this.state = {
    };
  }

  componentDidMount() {  }

  render() {
    return (
      <div className='App'>
        {/* <AppBarComponent /> */}
        <MuiThemeProvider theme={theme}>
          <ResponsiveDrawer />
        </MuiThemeProvider>
      </div>
    );
  }
}


export default App;
