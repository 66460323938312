import React from 'react';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import SuccessIcon from '../custom-icons/success.components';

import './success.css';

export const StatusSuccess = ({ query }) => {
  // TODO: change success animate
  return (<>
    {query === 'success' ? (
      <Typography>
        <SuccessIcon />
      </Typography>
    ) : (
        <Fade
          in={query === 'progress'}
          style={{
            transitionDelay: query === 'progress' ? '200ms' : '0ms',
          }}
          unmountOnExit
        >
          <CircularProgress />
        </Fade>
      )}
  </>);
};