import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
// import Grow from '@material-ui/core/Grow';
import { Parallax } from 'react-parallax';
import LinearProgress from '@material-ui/core/LinearProgress';

import { selectCollectionLastId, selectCarAviableBand } from '../../redux/car/car.selectors';
import { formatMoney } from '../../components/common-utils';
import SimpleBackdrop from '../../components/backdop/backdop.component';
import { fetchCollectionStartAsync } from '../../redux/car/car.actions.js';
// import ImageBg1 from '../../assets/bg_1.jpg';

// import BannerHome from '../../components/home-carousel/home-carousel.components'; 
import CarouselHome from '../../components/home-carousel/carousel.component';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  fullScreen: {
    padding: 0,
    margin: 0,
  },
  fontHilight: {
    fontFamily: 'kanit',
    // textShadow: '0 0 3px #FF0000, 0 0 5px #0000FF',
    // color: 'white',
    fontWeight: 'bold'
  },
  btnBandner: {
    position: 'absolute',
    top: 0,
  },
  recommendedTitle: {
    fontFamily: 'kanit',
    padding: '2%',
    // color: '#fff',
    // textShadow: '5px 5px 10px ' + theme.palette.primary.dark,
    fontWeight: 'bold'
  },
  arrowDown: {
    color: 'white',
    position: 'absolute',
    bottom: 10,
    animation: '$fadeAnimation 1000ms infinite',
  },
  image: {
    backgroundImage: `url(https://doyoautocar-img.s3-ap-southeast-1.amazonaws.com/info/bg_1.jpg)`, // url(https://source.unsplash.com/random)
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    // filter: 'brightness(50%)',
  },
  bottomShow: {
    padding: theme.spacing(3),
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bottomHover: {
    boxShadow: '0px 0px 5px 5px ' + theme.palette.primary.light,
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.grey[50],
    }
  },
  "@keyframes bottomAnimate": {
    '0%': {
      backgroundColor: 'red'
    },
    '25%': {
      backgroundColor: 'yellow'
    },
    '50%': {
      backgroundColor: 'blue'
    },
    '100%': {
      backgroundColor: 'green'
    }
  },
  "@keyframes fadeAnimation": {
    from: { opacity: 0.1 },
    to: { opacity: 1 },
  },
  bottomAnimate: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    animation: '$fadeAnimation 2000ms infinite',
  },
}));

const carInfoStyleTitle = {
  fontWeight: 'fontWeightBold',
  fontSize: 20,
  textAlign: 'right',
  style: {
    fontFamily: 'kanit',
    // textShadow: '0 0 3px #FF0000, 0 0 5px #0000FF',
    // color: 'white',
  },
};

const carInfoStyleSub = {
  textAlign: 'left',
  fontWeight: 'fontWeightBold',
  fontSize: 20,
  style: {
    fontFamily: 'kanit',
  },
};

const boxShadow = '0 8px 8px 0 rgba(255, 255, 255, 0.5), 0 18px 30px 0 rgba(255, 255, 255, 0.5)';


const HomePage = ({ cars, band, isLoading }) => {
  const classes = useStyles();

  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleToggle = () => {
    setOpenBackdrop(!openBackdrop);
  };

  const changeUrl = (path) => {
    handleToggle();

    const getUrl = window.location;
    const baseUrl = getUrl.protocol + "//" + getUrl.host;
    window.location.href = baseUrl + path;
  };

  const gotoCars = () => {
    changeUrl('/car');
  };

  const gotoSellContract = () => {
    changeUrl('/sell-car');
  };

  const gotoContract = () => {
    changeUrl('/contact');
  };

  const GetBtnAction = () => {
    const btnActionObj = [
      {
        text: 'รถยนต์ทั้งหมด',
        handle: gotoCars,
      },
      {
        text: 'ติดต่อขายรถ',
        handle: gotoSellContract,
      },
      {
        text: 'ติดต่อเรา',
        handle: gotoContract,
      }
    ];

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        {
          btnActionObj.map((obj, index) => {
            return (<Grid className={classes.bottomShow} item xs={11} sm={4} md={3} key={obj.text + '-' + index}>
              <Button className={classes.bottomHover}
                variant="outlined"
                fullWidth
                size="large"
                onClick={obj.handle}
                color="primary">
                <Box fontWeight="fontWeightBold" fontSize={20} fontFamily="kanit">{obj.text}</Box>
              </Button>
            </Grid>);
          })
        }
      </Grid>
    );
  };

  // const getBtnContact = () => {
  //   return (<Button className={clsx(classes.bottomHover, classes.bottomAnimate)}
  //     variant="outlined"
  //     size="large"
  //     color="primary"
  //     onClick={gotoContract}
  //   >
  //     <Box fontWeight="fontWeightBold">ติดต่อเรา</Box>
  //   </Button>);
  // };
  const GetCarRecommended = (car) => {
    const moneyCar = car && car.financial && car.financial.rows ? formatMoney(car.financial.header) : '';

    return car ? (<Grid item xs={12} key={'car-recommended-' + car.id}>
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={0}
      >
        <Grid item sm={1} xs={false}></Grid>
        <Grid item sm={6} xs={12} style={{ padding: '3%' }}>
          <Card style={{ maxWidth: 600, boxShadow: boxShadow }}>
            <CardActionArea>
              <CardMedia
                image={car.images[0]}
                alt="Contemplative Reptile"
                src='img'
                component="img"
                onClick={() => changeUrl('/car/' + car.id)}
              />
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item sm={4} xs={false}>
          <Hidden xsDown implementation="css">
            <Grid
              container
              spacing={5}
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', boxShadow: boxShadow }}
            >
              <Grid item xs={12}>
                <Box {...carInfoStyleTitle} textAlign="center" fontSize={25}>{car.name}</Box>
              </Grid>
              <Grid item xs={6}>
                <Box  {...carInfoStyleTitle}>ราคา</Box>
              </Grid>
              <Grid item xs={6}>
                <Box  {...carInfoStyleSub}>{moneyCar}</Box>
              </Grid>

              <Grid item xs={6}>
                <Box  {...carInfoStyleTitle}>ปี</Box>
              </Grid>
              <Grid item xs={6}>
                <Box  {...carInfoStyleSub}>{car.date}</Box>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>

        <Grid item xs={12} md={8} fontSize={20} style={{
          fontFamily: 'kanit',
          // backgroundColor: 'white',
          // boxShadow: boxShadow,
          padding: '2%',
        }}>
          <Hidden smUp implementation="css">
            <Box {...carInfoStyleTitle} textAlign="center" fontSize={15}>{car.name}</Box>
            <Box>ปี <font className={classes.fontHilight}>{car.date}</font>, เป็นเจ้าของได้เพียง <font className={classes.fontHilight}>{moneyCar}</font></Box>
            <br />
          </Hidden>
        </Grid>
      </Grid>
    </Grid>) : (<></>);
  };

  const GetCarShowing = () => {
    return cars ? (<>
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={0}
      >
        <Grid item xs={12}>
          <Box key="title" fontWeight='fontWeightBold' fontSize={30} className={classes.recommendedTitle}>
            <div style={{ textDecoration: 'underline' }}>รถยนต์แนะนำ</div>
          </Box>
        </Grid>
        {cars.map(car => GetCarRecommended(car))}
      </Grid>
    </>) : (<></>);
  };

  // const GetTextTitle = (textSize) => {
  //   return (<>
  //     <Box key="title" fontWeight='fontWeightBold' fontSize={textSize} style={{
  //       color: 'Black',
  //       opacity: 0.9,
  //       fontFamily: 'kanit',
  //       padding: '2%'
  //     }}>ยินดีต้อนรับสู่ Doyo Autocar</Box>
  //   </>
  //   );
  // };

  return (
    <Container className={clsx(classes.fullScreen)} maxWidth={false}>
      <Grid
        container
        component="main"
        spacing={0}
      >
        <CssBaseline />


        {/*TODO: Page title <Grid item xs={12}>
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={1}
          >
            <Grow
              in
              timeout={1000}
            >
              <Grid item xs={12}>
                <Hidden smUp implementation="css">
                  {GetTextTitle(40)}
                </Hidden>
                <Hidden xsDown implementation="css">
                  {GetTextTitle(80)}
                </Hidden>
              </Grid>
            </Grow>
          </Grid>
        </Grid> */}

        {/* <Grid item xs={12}>
          <BannerHome />
        </Grid> */}
        <Grid item xs={12}>
          <section><CarouselHome /></section>

        </Grid>

        {isLoading ? (<Grid item xs={12}>
          <BorderLinearProgress />
        </Grid>) : ''}

        <Grid item xs={12}>
          <br /><br />{GetBtnAction()}
        </Grid>

        {band ? (
          <Grid item xs={12}>
            <Grid
              container>
              {
                band.map((b, index) => {
                  return b && b.band && b.band.toLowerCase() ? (
                    <Grid item key={index}
                      xs={4}
                      sm={3}
                      md={2}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Card style={{ maxWidth: 99, boxShadow: boxShadow, }}
                        onClick={() => changeUrl('/brand/cars/' + b.band)}>
                        <CardActionArea>
                          <CardMedia
                            image={'https://doyoautocar-img.s3-ap-southeast-1.amazonaws.com/info/band/' + b.band.toLowerCase() + '.png'}
                            src='img'
                            component="img"
                          />
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ) : (<></>);
                })
              }
            </Grid>
          </Grid>
        ) : ''}

        <Grid item xs={12}>
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={12}>
              {GetCarShowing()}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Parallax
            blur={{ min: -1500, max: 1500 }}
            bgImage="https://doyoautocar-img.s3-ap-southeast-1.amazonaws.com/info/baner_2.jpg"
            bgImageAlt="the bg 1"
            strength={200}
          >
            <Hidden smUp implementation="css"><div style={{ height: '400px' }} ></div></Hidden>
            <Hidden xsDown implementation="css"><div style={{ height: '650px' }} ></div></Hidden>
          </Parallax>
        </Grid>
      </Grid>
      <SimpleBackdrop
        open={openBackdrop || isLoading}
        handleClose={handleCloseBackdrop}
      />
    </Container >
  );
};

const mapStateToProps = (state, ownerProps) => {
  return {
    cars: selectCollectionLastId(state),
    band: selectCarAviableBand(state),
    isLoading: ((state) => (state.cars.isFetching))(state)
  }
};


const mapDispathToProps = (dispatch) => ({
  fetchCollectionStartAsync: () => dispatch(fetchCollectionStartAsync())
});

export default connect(mapStateToProps, mapDispathToProps)(HomePage);
