/* eslint-disable no-mixed-operators */
import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import md5 from 'md5';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import WorkIcon from '@material-ui/icons/Work';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { DropzoneArea } from 'material-ui-dropzone';
import { red, green } from "@material-ui/core/colors";
import Hidden from '@material-ui/core/Hidden';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
// import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography, Fab } from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";

import HorizontalLabelPositionBelowStepper from '../stepper/stepper.components';
import { StatusSuccess } from '../status/status.components';
import CarDetailsComponents from '../car-details/car-details.components';
import { imageFileToBase64 } from '../common-utils';

import { uploadImageBase64, getUrlImage } from '../../aws/aws.utils';

const dataType = {
  string: 'string',
  array: 'array',
  object: 'object',
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 720,
    backgroundColor: theme.palette.background.paper,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  prevImg: {
    maxWidth: '300px',
  },
  removeImg: {
    top: 0,
    right: 0,
  },
  imageBlue: {
    filter: 'blur(4px)'
  },
  rootDialog: {
    Width: '100%',
    Height: "100%"
  },
  red: {
    color: red[500]
  },
  green: {
    color: green[500]
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const BaseDialog = (props) => {
  const classes = useStyles();
  // const errorMessage = {};
  const { open, handleClose, defaultCarData, carData, setCarDataState, finishAction, steps, title, handlePreviewImage, allBrand } = props;
  const defaultState = {
    imagesPrev: carData.images,
    iconIsRemoveImg: carData.images.map(_ => (false)),
    previewImage: carData.images,
    uploadNewImageAsync: [],
    directors_array: [],
  };
  const [state, setState] = React.useState({
    ...defaultState
  });

  const [confirmImage, setConfirmImage] = React.useState(carData.images);
  const [tempPriceState, setTempPriceState] = React.useState({
    addBtnShow: true,
    nameValue: null
  });

  const [filesStr, setFilesStr] = React.useState({
    items: [],
    files: [],
    loading: false,
  });

  const [query, setQuery] = React.useState('progress');
  const timerRef = React.useRef();

  const [activeStep, setActiveStep] = React.useState(0);

  const resetState = () => {
    setState({
      ...defaultState
    });
  };
  const handleStepNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStepBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepReset = () => {
    setActiveStep(0);
    setCarDataState(defaultCarData);
    resetState();
  };

  const handleNextStepBtn = () => {
    switch (activeStep) {
      case 0:
        // handleCheckAddCar();
        if (handlePreviewImage) {
          const previewImage = handlePreviewImage(state.previewImage, state);
          setState({
            ...state,
            previewImage,
          });
        }
        handleStepNext();
        break;
      case 1:
        handleLastStep();
        handleStepNext();
        break;
      default:
        break;
    }
  };

  const handleLastStep = () => {
    const data = {
      ...carData,
      images: confirmImage
    };

    // TODO: remove simulate
    setQuery('progress');
    timerRef.current = setTimeout(() => {
      finishAction(data, state);
      setQuery('success');

      resetState();
    }, 1000);
  };

  const carNameChange = (event) => {
    setCarDataState({
      ...carData,
      name: event.target.value
    });
  };

  const carBrandChange = (event, values) => {
    setCarDataState({
      ...carData,
      brand: values && values.value || event.target.value
    });

  };

  const carDescriptionChange = (event) => {
    setCarDataState({
      ...carData,
      description: event.target.value
    });
  };

  const carDateChange = (event) => {
    setCarDataState({
      ...carData,
      date: event.target.value
    });
  };

  const carModelChange = (event) => {
    setCarDataState({
      ...carData,
      model: event.target.value
    });
  };

  const carEngineSizeChange = (event) => {
    setCarDataState({
      ...carData,
      engineSize: event.target.value
    });
  };

  const carEngineTypeChange = (event, values) => {
    setCarDataState({
      ...carData,
      engineType: values && values.value || event.target.value
    });
  };

  const carTransmissionTypeChange = (event, values) => {
    setCarDataState({
      ...carData,
      transmissionType: values && values.value || event.target.value
    });
  };

  const financialTotalPriceChange = (event) => {
    const financial = carData.financial;
    const value = +event.target.value;

    if (!isNaN(value)) {
      setCarDataState({
        ...carData,
        financial: {
          ...financial,
          header: value
        }
      });
    }
  };

  const financialPriceChange = (label, valueIn) => {
    const financial = carData.financial;
    const rows = carData.financial.rows;
    const value = +valueIn;


    if (!isNaN(value) && label) {
      const tempRows = { ...rows };
      tempRows[label] = value;

      setCarDataState({
        ...carData,
        financial: {
          ...financial,
          rows: tempRows
        }
      });
    }
  };

  const financialDownPriceChange = (event) => {
    financialPriceChange('ออกรถใช้เงิน', event.target.value);
  };

  const handleCloseDialog = () => {
    handleClose();
    handleStepReset();
  };
  const fieldsToAdd = [
    {
      id: 'name',
      type: dataType.string,
      label: 'ชื่อรถ',
      onChange: carNameChange
    }, {
      id: 'brand',
      type: dataType.string,
      label: 'ยี่ห้อรถ',
      onChange: carBrandChange,
      select: allBrand.map(brand => {
        return {
          value: brand.band,
          label: brand.band
        };
      })
    }, {
      id: 'description',
      type: dataType.string,
      label: 'รายละเอียด',
      onChange: carDescriptionChange
    }, {
      id: 'date',
      type: dataType.string,
      label: 'ปี',
      onChange: carDateChange
    }, {
      id: 'model',
      type: dataType.string,
      label: 'รุ่น',
      onChange: carModelChange
    }, {
      id: 'engineSize',
      type: dataType.string,
      label: 'ขนาดเครื่องยนต์',
      onChange: carEngineSizeChange
    }, {
      id: 'engineType',
      type: dataType.string,
      label: 'ชนิดเครื่องยนต์',
      onChange: carEngineTypeChange,
      select: [
        {
          value: 'เบนซิล',
          label: 'เบนซิล'
        },
        {
          value: 'ดีเซล',
          label: 'ดีเซล'
        }
      ]
    }, {
      id: 'transmissionType',
      type: dataType.string,
      label: 'เกียร์',
      onChange: carTransmissionTypeChange,
      select: [
        {
          value: 'ออโต้',
          label: 'ออโต้'
        },
        {
          value: 'ธรรมดา',
          label: 'ธรรมดา'
        }
      ]
    }, {
      id: 'financial',
      type: dataType.object,
      label: 'ราคา',
    }, {
      id: 'images',
      type: dataType.array,
      label: 'รูปภาพ',
    }
  ];

  const getRawData = (id) => {
    return carData[id];
  };

  const uploadNewImage = () => {
    // wait for upload image
    Promise.all(filesStr.items).then(filePreUpload => {
      // change base 64 to url
      setFilesStr({
        ...filesStr,
        loading: true,
      });

      timerRef.current = setTimeout(() => {
        const s3PathAsync = [];
        filePreUpload.forEach((data, index) => {
          const dateCurrent = md5(moment().toString());
          const s3Path = 'car-image/' + carData.id + '/' + dateCurrent + '-' + filesStr.files[index].name;

          s3PathAsync.push(uploadImageBase64(s3Path, data).then(async (res) => {
            return await getUrlImage(s3Path);
          }).then((url) => {
            return url;
          }));
        });

        Promise.all(s3PathAsync)
          .then((urls) => {
            const previewImgs = [...state.previewImage, ...urls];
            if (previewImgs.length > 0) { setConfirmImage(previewImgs); }

            setState({
              ...state,
              previewImage: previewImgs,
              iconIsRemoveImg: [...state.iconIsRemoveImg, ...(urls.map(_ => (false)))]
            });
          })
          .finally(() => {
            setFilesStr({
              ...filesStr,
              loading: false,
            });
          });
      }, 500);
    });
  };


  const handleUploadImageChange = (files) => {
    setFilesStr({
      ...filesStr,
      loading: true,
    });

    timerRef.current = setTimeout(() => {
      setFilesStr({
        ...filesStr,
        files: files,
        items: files.map((file) => {
          return imageFileToBase64(file);
        }),
        loading: false,
      });
    }, 1000);
  };

  const handleRemoveImage = (index) => {
    // change icon and image bure
    const btnState = state.iconIsRemoveImg;
    btnState[index] = !btnState[index];

    setState({
      ...state,
      iconIsRemoveImg: btnState,
    });
  };

  const ImageLists = () => {
    return (<Grid container spacing={3}>
      {
        state.previewImage.map((img, index) => {
          return (
            <Grid item xs={6} md={3} key={'edit-car-img-' + index}>
              <Card className={clsx(classes.prevImg)}>
                <CardMedia
                  image={img}
                  title="Paella dish"
                  alt="Contemplative Reptile"
                  src='img'
                  component="img"
                  className={state.iconIsRemoveImg[index] ? classes.imageBlue : undefined}
                />

                <IconButton
                  onClick={() => (handleRemoveImage(index))}
                  aria-label="show more"
                  className={classes.removeImg}
                >
                  {state.iconIsRemoveImg[index] ? (<DeleteForeverIcon />) : (<DeleteIcon />)}
                </IconButton>
              </Card>
            </Grid>
          );
        })
      }
    </Grid>);
  };

  const removePreviewUploadFile = (file) => {
    console.log('removePreviewUploadFile:filesStr', filesStr);
    console.log('removePreviewUploadFile:file', file);
  };

  const appendInput_director = (name) => {
    setState({
      ...state,
      directors_array: [...state.directors_array, name]
    });
  };

  const removeInput = () => {
    setState({
      ...state,
      directors_array: []
    });
  };

  const getDisplayOldFinancialData = (data) => {
    const formatData = [];
    Object.keys(data).forEach((value, index) => {
      if (data && value !== 'ออกรถใช้เงิน' && value !== 'ยอดผ่อนชำระ/เดือน(บาท)') {
        formatData.push({
          name: value,
          price: data[value]
        });
      }
    });
    return formatData;
  };

  const showAddFields = () => {
    setTempPriceState({
      ...tempPriceState,
      addBtnShow: false
    });
  }

  const confirmAddPriceField = () => {
    if (tempPriceState.nameValue) {
      appendInput_director(tempPriceState.nameValue);
      financialPriceChange(tempPriceState.nameValue, '');
      setTempPriceState({
        ...tempPriceState,
        addBtnShow: true
      });
    }
  };

  const handleChangeTempPrice = (value, index) => {
    financialPriceChange(index, value);

    if (state.directors_array.length > 0) {
      removeInput();
    }
  };

  const getFinancialTemplate = (data, index, keyName) => {
    return [
      <Grid item xs={4} key={'getFinancialTemplate_' + keyName + index}>
        <TextField
          margin="dense"
          fullWidth
          variant='outlined'
          value={data.name || ''}
          readOnly
        />
      </Grid>,
      <Grid item xs={8} key={keyName + index + '_getFinancialTemplate'}>
        <TextField
          margin="dense"
          fullWidth
          label="จำนวนเงิน"
          value={data.price || ''}
          onChange={(event) => { handleChangeTempPrice(event.target.value, data.name) }}
        />
      </Grid>
    ];
  };

  const getTemplateAdd = () => {
    return [
      <Grid item xs={4}>
        <TextField
          margin="dense"
          fullWidth
          label="ระยะเวลา เช่น 60 เดือน, 72 เดือน, ....."
          value={tempPriceState.nameValue || ''}
          onChange={(event) => { setTempPriceState({ ...tempPriceState, nameValue: event.target.value }) }}
          required
        />
      </Grid>,
      <Grid item xs={8}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => { confirmAddPriceField() }}>ยืนยัน</Button>
      </Grid>
    ];
  };

  const financialSection = (index) => {
    const rows = { ...carData.financial.rows };
    return [
      <Grid container spacing={3}>
        {getDisplayOldFinancialData(rows).map((data, index) => {
          return getFinancialTemplate(data, index, 'financialOld_');
        })}
        {tempPriceState.addBtnShow ? '' :
          getTemplateAdd()}
        {/* {state.directors_array.map((name, index) => (getFinancialTemplate({ name }, index, 'Additional_')))} */}
      </Grid>
    ];
  };

  const getList = (type) => {
    return (
      <>
        <Grid container spacing={3}>
          {
            fieldsToAdd.filter(f => type === f.type).map((field, index) => {
              switch (field.type) {
                case dataType.string: {
                  return (<Grid item xs={12} key={'getList_' + index}>
                    {(field.select) ? (
                      <Autocomplete
                        id={field.id + '_combo_box'}
                        options={field.select}
                        getOptionLabel={(option) => option && option.value ? option.value : option}
                        onChange={field.onChange}
                        defaultValue={getRawData(field.id).replace('"', '')}
                        renderInput={(params) => <TextField {...params} label={field.label} variant="outlined" id={field.id} />}
                      />
                    ) : (<TextField
                      margin="dense"
                      id={field.id}
                      label={field.label}
                      fullWidth
                      onChange={field.onChange}
                      value={getRawData(field.id)}
                    />)}
                  </Grid>);
                }
                case dataType.array: {
                  // images
                  return (
                    <Grid item xs={12} key={'array-' + index}>
                      <ImageLists
                        carData={carData}
                        key='ImageLists'
                      />
                      <br />
                      <div className={classes.wrapper}>
                        <Button
                          className={classes.green}
                          onClick={uploadNewImage}
                          disabled={filesStr.loading}
                          variant="outlined">
                          อัพโหลดรูปภาพ
                      </Button>
                        <Typography>ต้อง upload รูปก่อน มิฉนั้นจะไม่มีรูป</Typography>
                        {filesStr.loading ? (<LinearProgress />) : ''}
                      </div>
                      <br />
                      <Grid container spacing={3}>
                        <Grid item xs={12} key={'upload-' + index}>
                          <DropzoneArea
                            key='DropzoneArea'
                            filesLimit={10}
                            acceptedFiles={['image/*']}
                            dropzoneText={"อัพโหลดไฟล์ที่นี่"}
                            onChange={handleUploadImageChange}
                            showPreviews={false}
                            showPreviewsInDropzone={true}
                            onDelete={removePreviewUploadFile}
                          />
                        </Grid>
                      </Grid>
                      <br />
                    </Grid>
                  );
                }
                case dataType.object: {
                  // financial sections
                  return [
                    <Grid item xs={12} key={'object-total-' + index}>
                      <TextField
                        margin="dense"
                        id="total-price"
                        label="ราคาทั้งหมด"
                        fullWidth
                        onChange={financialTotalPriceChange}
                        value={carData.financial.header}
                      /></Grid>,
                    ...financialSection(index),
                    <Grid item xs={12} key={'object-used-' + index}>
                      <TextField
                        margin="dense"
                        id="price-down"
                        label="ออกรถใช้เงิน"
                        fullWidth
                        onChange={financialDownPriceChange}
                        value={carData.financial.rows['ออกรถใช้เงิน']}
                      /></Grid>
                  ];
                }
                default: {
                  return (<></>);
                }
              }
            })

          }

        </Grid>
      </>
    );
  };

  const dialogContentFirst = (<List className={classes.root}>
    <ListItem>
      <Hidden xsDown implementation="css">
        <ListItemAvatar>
          <Avatar>
            <WorkIcon />
          </Avatar>
        </ListItemAvatar>
      </Hidden>
      {getList('string')}
    </ListItem>
    <Divider />
    <ListItem>
      <Hidden xsDown implementation="css">
        <ListItemAvatar>
          <Avatar>
            <MonetizationOnIcon />
          </Avatar>
        </ListItemAvatar>
        <InputAdornment position="end">
          <Tooltip title="Add Statement">
            <Fab
              color="primary"
              size="small"
              disabled={!tempPriceState.addBtnShow}
              onClick={() => showAddFields()}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </InputAdornment>
      </Hidden>
      {getList('object')}
    </ListItem>
    <Divider />
    <ListItem>
      {getList('array')}
    </ListItem>
  </List>);

  // TODO: support fail case
  const dialogStatus = (<Grid container spacing={3}>
    <Grid item xs={12}>
      <Paper className={classes.paper}><StatusSuccess query={query} /></Paper>
    </Grid>
  </Grid >);

  const dialogPreview = (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CarDetailsComponents carData={{ ...carData, images: state.previewImage, uploadNewImageAsync: filesStr }} isEdit={true} />
      </Grid>
    </Grid >);

  const getdialogContent = (step) => {
    switch (step) {
      case 0:
        return dialogContentFirst;
      case 1:
        return dialogPreview;
      case 2:
        return dialogStatus;
      default:
        return (<h1>ERROR</h1>);
    }
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      className={classes.rootDialog}
      open={open}
      onClose={handleClose}
      fullWidth
      aria-labelledby="form-dialog-title"
      maxWidth='xl'
      TransitionComponent={Transition}
      height="100%"
    >
      <DialogTitle id="form-dialog-title" height="100%">
        {title || ''}
      </DialogTitle>
      <DialogContent>
        <HorizontalLabelPositionBelowStepper
          activeStep={activeStep}
          handleNext={handleStepNext}
          handleBack={handleStepBack}
          handleReset={handleStepReset}
          steps={steps} />
        {getdialogContent(activeStep)}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          className={classes.red}
          disabled={activeStep === steps.length - 1}
          variant="outlined">
          ยกเลิก
        </Button>
        <Button
          disabled={activeStep === 0 || activeStep === steps.length - 1}
          onClick={handleStepBack}
          className={classes.backButton}
        >
          ย้อนกลับ
            </Button>
        <Button
          onClick={activeStep === steps.length - 1 ? handleCloseDialog : handleNextStepBtn}
          className={classes.green}
          disabled={filesStr.loading || (activeStep === steps.length - 1 && query === 'progress')}
          variant="contained">
          {activeStep === steps.length - 1 ? 'เสร็จสิ้น' : 'ถัดไป'}
        </Button>
      </DialogActions>
    </Dialog>);
};

export default BaseDialog;