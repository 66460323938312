import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import CarsPage from '../cars/cars.components';
import CarPage from '../car/car.pages';
import { getTitle } from '../../components/common-utils';
import { selectCollections } from '../../redux/car/car.selectors';
import { fetchCollectionStartAsync } from '../../redux/car/car.actions';
import { selectLastUpdate } from '../../redux/car/car.selectors';
import { PAGE_TITLE } from '../../components/common-utils';

class CarsNevigatePage extends React.Component {

  componentDidMount() {
    const pageTitle = getTitle(window.location.pathname);
    const { fetchCollectionStartAsync, lastUpdate, cars } = this.props;

    // check time for update
    const homeLastUpdate = moment(lastUpdate, 'YYYY-MM-DD HH:mm');
    const dateNow = moment();
    console.log('CarsNevigatePage:dateNow', dateNow.format('YYYY-MM-DD HH:mm').toString());

    const duration = moment.duration(dateNow.diff(homeLastUpdate));
    const minutes = duration.asMinutes();
    console.log('CarsNevigatePage:minutes', minutes);

    const isFecging = (!cars || cars.length < 1 || !minutes || minutes >= 10) && pageTitle === PAGE_TITLE.ALL_CAL;
    console.log('CarsNevigatePage:is feching', isFecging);

    if (isFecging) {
      console.log('fetchCollectionStartAsync', fetchCollectionStartAsync());
    }
  }

  render() {
    const { match } = this.props;

    return (<div>
      <Route exact path={`${match.path}/:carId`} component={CarPage} />
      <Route exact path={`${match.path}`} component={CarsPage} />
    </div>);
  }
}

const mapStateToProps = (state, ownerProps) => {
  return {
    lastUpdate: selectLastUpdate(state),
    cars: selectCollections(state),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCollectionStartAsync: () => dispatch(fetchCollectionStartAsync())
});

export default connect(mapStateToProps, mapDispatchToProps)(CarsNevigatePage);
