export default [{
    "band": "Toyota"
  },
  {
    "band": "Honda"
  },
  {
    "band": "Mazda"
  },
  {
    "band": "Nissan"
  },
  {
    "band": "Mitsubishi"
  },
  {
    "band": "Suzuki"
  },
  {
    "band": "Mercedes-Benz"
  },
  {
    "band": "BMW"
  },
  {
    "band": "Audi"
  },
  {
    "band": "Bentley"
  },
  {
    "band": "Chevrolet"
  },
  {
    "band": "Chrysler"
  },
  {
    "band": "Citroen"
  },
  {
    "band": "Dfsk"
  },
  {
    "band": "Ford"
  },
  {
    "band": "Foton"
  },
  {
    "band": "Hyundai"
  },
  {
    "band": "Isuzu"
  },
  {
    "band": "Jaguar"
  },
  {
    "band": "Jeep"
  },
  {
    "band": "Kia"
  },
  {
    "band": "Lamborghini"
  },
  {
    "band": "Land Rover"
  },
  {
    "band": "Lexus"
  },
  {
    "band": "Maserati"
  },
  {
    "band": "Mg"
  },
  {
    "band": "Mini"
  },
  {
    "band": "Mitsuoka"
  },
  {
    "band": "Peugeot"
  },
  {
    "band": "Porsche"
  },
  {
    "band": "Proton"
  },
  {
    "band": "Rolls-royce"
  },
  {
    "band": "Ssangyong"
  },
  {
    "band": "Subaru"
  },
  {
    "band": "Tata"
  },
  {
    "band": "Triumph"
  },
  {
    "band": "Volkswagen"
  },
  {
    "band": "Volvo"
  },
  {
    "band": "Hino"
  }
];