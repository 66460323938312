import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function SimpleBackdrop({ open, setOpen, handleClose }) {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (<Backdrop className={classes.backdrop} open={open === undefined ? true : open} onClick={handleClose}>
    <CircularProgress color="inherit" />
  </Backdrop>
  );
}
