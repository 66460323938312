import React from 'react';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import SimpleBackdrop from '../backdop/backdop.component';
// import Image from 'material-ui-image';
import { formatMoney } from '../common-utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    'flex-direction': 'column',
    'border-radius': '5px',
    cursor: 'pointer',
    '-moz-osx-font-smoothing': 'grayscale',
    'backface-visibility': 'hidden',
    transform: 'translateZ(0)',
    transition: 'transform 0.25s ease-out',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  media: {
    'max-width': '100%',
    height: '250px',
  },
  centerCropped: {
    'background-position': 'center center',
    'background-repeat': 'no-repeat',
  },
  mediaImage: {
    maxWidth: '100%',
    height: 'auto',
  },
}));


export const CardCarDetail = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const timer = React.useRef();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClick = () => {
    handleToggle();
    const getUrl = window.location;
    const baseUrl = getUrl.protocol + "//" + getUrl.host;
    const url = baseUrl + '/car/' + props.car.id;
    // window.location.href = url;
    window.open(url, "_blank");
    timer.current = setTimeout(() => {
      handleClose();
    }, 1000);
  }

  const specDetails = [
    {
      name: 'รุ่น',
      value: props.car.model
    },
    {
      name: 'ปี',
      value: props.car.date
    },
    {
      name: 'เกียร์',
      value: props.car.transmissionType
    },
    {
      name: 'ขนาดเครื่องยนต์',
      value: props.car.engineSize
    },
    {
      name: 'ชนิดเครื่องยนต์',
      value: props.car.engineType
    }
  ];

  return (
    <div>
      <Card className={classes.root}>
        <CardActionArea onClick={handleClick} id={props.car.carId}>
          <CardMedia
            className={clsx(classes.centerCropped, classes.media)}
            image={props.car.images[0]}
            alt="Contemplative Reptile"
            src='img'
            component="img"
          />
          {/* <Image className={clsx(classes.centerCropped, classes.mediaImage)} src={props.car.images[0]} /> */}
          <CardContent style={{ padding: '6px' }}>
            <Typography gutterBottom variant="h5" component="h2">
              {props.car.name}{' '}
              {props.car.date ? (<b>[{props.car.date}]</b>) : ''}{' '}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions onClick={handleExpandClick}>
          <Grid container
            direction="row"
            justify="space-between"
            alignItems="center">
            <Grid item xs={6}>
              <Typography gutterBottom variant="h5" component="h2">
                {props.car.financial.header ? (<b>{formatMoney(props.car.financial.header)}</b>) : ''}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </Grid>
          </Grid>

        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container spacing={3}>
              {specDetails.map((data, index) => {
                return [
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={index.toString() + data.name}
                  >
                    <Box
                      textAlign="left"
                      fontWeight="fontWeightBold">{data.name}</Box>
                    <Hidden smDown implementation="css"><Divider /></Hidden>
                  </Grid>,
                  <Grid item xs={12} md={6} key={index.toString() + data.value}>
                    <Box textAlign="right">{data.value}</Box>
                    {data.value ? (<Divider />) : ''}
                  </Grid>
                ];
              })}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleClick}
                >ดูรายละเอียด</Button>
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
      <SimpleBackdrop
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
};
