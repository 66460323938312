import React from 'react';
import { blue } from "@material-ui/core/colors";

const FacebookIcon = ({ url }) => {
  return (
    <a href={url} target="blank" title="facebook">
      <svg height="32" width="32" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ backgroundColor: blue[500], borderRadius: '10%', borderColor: 'rgb(255, 255, 255)', borderStyle: 'solid', borderWidth: '0px' }}>
        <g>
          <path fill="#FFF" id="f_1_" d="M23.3147057,35 L18.4372418,35 L18.4372418,23.4985483 L16,23.4985483 L16,19.535534 L18.4372418,19.535534 L18.4372418,17.1562737 C18.4372418,13.9234411 19.8127074,12 23.7230238,12 L26.9776468,12 L26.9776468,15.964466 L24.9435074,15.964466 C23.4212558,15.964466 23.3206665,16.5182719 23.3206665,17.5518493 L23.3139606,19.535534 L27,19.535534 L26.5685836,23.4985483 L23.3139606,23.4985483 L23.3139606,35 L23.3147057,35 Z"></path>
        </g>
      </svg>
    </a>
  );
};

export default FacebookIcon;