import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import { SearchBox } from '../search-box/search-box.component';
import { DrawerConponents } from '../drawer/drawer.components';
import { setSearchText } from '../../redux/car/car.actions';
import { selectCollection } from '../../redux/car/car.selectors';
import useStylesCommon from '../common.styles';

import LoginDialog from '../login-dialog/login-dialog.components';
import { PAGE_TITLE } from '../common-utils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 30,
  },
  titleStyle: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const SearchAppBar = (props) => {
  const classes = useStyles();
  const classesCommon = useStylesCommon();
  const pageTitle = props.pageTitle;

  const onSearchChange = event => {
    // this.setState({ searchField: event.target.value });
    props.setSearchText({
      ...props.state.cars,
      search: event.target.value
    });
  };

  const displayTitle = () => {
    if (props.sendCarId) {
      return props.selectCollection?.name;
    } else {
      return pageTitle;
    }
  };

  return (
    <AppBar position="fixed" className={classesCommon.appBar}>
      <Toolbar>
        <DrawerConponents />
        <Typography className={clsx(classes.titleStyle, pageTitle === PAGE_TITLE.ALL_CAL ? classes.title : undefined)} variant="h6" noWrap>
          {displayTitle()}
        </Typography>
        {pageTitle === PAGE_TITLE.ALL_CAL ? (<SearchBox onSearchChange={onSearchChange} />) : ''}
        {pageTitle === PAGE_TITLE.ALL_CAL ? (<LoginDialog isSearch />) : (<LoginDialog />)}
      </Toolbar>
      {props.isLoading ? (<LinearProgress color="secondary" />) : ''}
    </AppBar>
  );
}

const mapDispathToProps = (dispatch) => ({
  setSearchText: item => dispatch(setSearchText(item))
});

const mapStateToProps = (state, ownerProps) => {
  return {
    state,
    selectCollection: selectCollection(ownerProps.carId)(state),
    isLoading: ((state) => (state.cars.isFetching))(state)
  };
};


export default connect(mapStateToProps, mapDispathToProps)(SearchAppBar);
