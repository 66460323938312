export const filteredByNameCollection = (cars, name) => {
  return name ? cars.filter(car => car.name.toLowerCase().includes(name.toLowerCase())) : cars;
};

export const filteredByNameOrBrandCollection = (cars, name) => {
  return name ? cars.filter(car => {
    const carName = car.name.toLowerCase();
    const carBrand = car.brand.toLowerCase();

    return carBrand.includes(name.toLowerCase()) || carName.includes(name.toLowerCase());
  }) : cars;
};
