import React from 'react';
import BaseDialog from '../base-dialog/base-dialog.components';

const FormDialog = ({ steps, open, setOpen, defaultCarData, carData, setCarDataState, finishAction, title, handlePreviewImage, allBrand }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BaseDialog
      defaultCarData={defaultCarData}
      open={open}
      handleClose={handleClose}
      finishAction={finishAction}
      steps={steps}
      carData={carData}
      setCarDataState={setCarDataState}
      title={title || 'เพิ่มรถคันใหม่'}
      handlePreviewImage={handlePreviewImage}
      allBrand={allBrand}
    />
  );
}

export default FormDialog;
