import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
// import 'react-awesome-slider/dist/custom-animations/fall-animation.css';
import './awesome-slider.scss';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const getImages = () => {
  const res = [];
  for (let i = 1; i <= 4; i++) {
    res.push((<div key={'slider-image-' + i} data-src={`https://doyoautocar-img.s3-ap-southeast-1.amazonaws.com/info/welcome_images/bg_${i}.jpg`} />))
  }

  return res;
};

const slider = () => (
  <AutoplaySlider
    play={true}
    cancelOnInteraction={false} // should stop playing on user interaction
    interval={10000}
    animation="cubeAnimation"
  // animation="fallAnimation"
  >
    {getImages()}
  </AutoplaySlider>
);

export default slider;