// New generate key
export default {
  apiKey: "AIzaSyB3AIrMH6m3PMwNwSycI8iMQWH87DHaf08",
  authDomain: "doyo-autocar.firebaseapp.com",
  databaseURL: "https://doyo-autocar.firebaseio.com",
  projectId: "doyo-autocar",
  storageBucket: "doyo-autocar.appspot.com",
  messagingSenderId: "149190509871",
  appId: "1:149190509871:web:32e6d26cc3a10bcd7d0dda",
  measurementId: "G-DBT235QR3M"
};