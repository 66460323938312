import React from 'react';
import Iframe from 'react-iframe';

const GoogleMapComponents = (props) => {
  // const key = 'AIzaSyDsYq1JD5wqOHU1upVvGsfOCZSCtR7w4Pk';
  // const url = 'https://www.google.com/maps/embed/v1/directions?key='+key+'&origin=Oslo+Norway&destination=Telemark+Norway&avoid=tolls|highways';
  const url = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15499.657901916584!2d100.30174636977536!3d13.784028999999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9fdee0edd1462756!2z4LmC4LiU4LmC4Lii4LmI4Lit4Lit4LmC4LiV4LmJ4LiE4Liy4Lij4LmMIOC4i-C4t-C5ieC4rSDguILguLLguKIg4Lij4LiW4Lih4Li34Lit4Liq4Lit4LiH4LiX4Li44LiB4Lib4Lij4Liw4LmA4Lig4LiX!5e0!3m2!1sth!2sth!4v1530246869408';

  return (<Iframe
    url={url}
    id="google-map"
    className="googel-map-iframe"
    display="block"
    allowFullScreen
    frameBorder={0}
    width='100%'
    height='100%'
  />);
};

export default GoogleMapComponents
