const CarActionTypes = {
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  GET_CAR_BY_ID: 'GET_CAR_BY_ID',
  GET_BAND_ALL: 'GET_BAND_ALL',
  ADD_NEW_CAR: 'ADD_NEW_CAR',
  EDIT_CAR: 'EDIT_CAR',
  REMOVE_CAR: 'REMOVE_CAR',
  FETCH_COLLECTIONS_START: 'FETCH_COLLECTIONS_START',
  FETCH_COLLECTIONS_SUCCESS: 'FETCH_COLLECTIONS_SUCCESS',
  FETCH_COLLECTIONS_FAILURE: 'FETCH_COLLECTIONS_FAILURE',
  LOAD_ALL_BAND: 'LOAD_ALL_BAND ',
  RESET_STATE: 'RESET_STATE ',
}

export default CarActionTypes;