import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { CardCar } from '../card/card.component';

// import './card-list.styles.css';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'grid',
    margin: '0 auto',
  },
  grid: {
    padding: 0
  },
}));

export const CardList = (props) => {
  const classes = useStyles();

  // return (
  //   <div className='card-list'>
  //     {props.cars.map(car => (
  //       <Card key={car.id} car={car} />
  //     ))}
  //   </div>
  // );
  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container spacing={3}>
        {props.cars.map(car => (
          <Grid item xs={12} md={4} sm={6} key={car.id}>
            <CardCar car={car} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
