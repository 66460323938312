import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import { CardList } from '../../components/card-list/card-list.component';
import { selectCollectionSortByIdDesc, selectCarAllBand } from '../../redux/car/car.selectors';
import { selectCurrentUser } from '../../redux/user/user.sectors';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import useStylesCommon from '../../components/common.styles';
import FormDialog from '../../components/add-dialog/add-dialog.components';
import { carDataTemplate } from '../../components/common-utils';
import { addCollectionAsync } from '../../redux/car/car.actions';
import { checkLoginAdmin } from '../../components/common-utils';
import SimpleBackdrop from '../../components/backdop/backdop.component';
// import carsMock from '../../mock/mock_cars.json';

// const filteredCars = cars.filter(car =>
//   car.name.toLowerCase().includes(searchField.toLowerCase())
// );

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabAdd: {
    bottom: theme.spacing(2)
  },
  febGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[300],
    '&:hover': {
      backgroundColor: green[600],
    },
  }
}));

function ScrollTop(props) {
  const { children } = props;
  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <div onClick={handleClick} role="presentation">
      {children}
    </div>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const CarsPage = ({ cars, user, addCollectionAsync, isLoading, allBrand }) => {
  const classes = useStyles();
  const classesCommon = useStylesCommon();
  const firstCar = cars[0];
  const defaultCarData = carDataTemplate((firstCar && firstCar.id) ? +firstCar.id : 1);

  const [open, setOpen] = React.useState(false);
  const [carDataDialog, setCarDataStateDialog] = React.useState(defaultCarData);

  const isAdmin = user
    && user.currentUser
    && user.currentUser.username
    && user.currentUser.password ?
    checkLoginAdmin(user.currentUser.username, user.currentUser.password)
    : false;

  const addData = (data) => {
    addCollectionAsync(data);
  };

  return (<div className={clsx(classesCommon.appBar, classes.root)}>
    <div id="back-to-top-anchor">
    </div>
    {isLoading ? (<SimpleBackdrop />) : ''}
    <CardList cars={cars} />

    <div className={clsx(classes.fab)}>
      {cars.length >= 3 ? (<ScrollTop>
        <Fab color="secondary" size="medium" aria-label="scroll back to top" className={clsx(classes.febGreen)}>
          <UpIcon />
        </Fab>
      </ScrollTop>) : ''}
      {isAdmin ? (<>
        <Fab
          color="secondary"
          size="medium"
          aria-label="add"
          className={clsx(classes.fabAdd)}
          onClick={() => { setOpen(true) }}>
          <AddIcon />
        </Fab>
        <FormDialog
          key='add-cars-dialog'
          open={open}
          setOpen={setOpen}
          carData={carDataDialog}
          setCarDataState={setCarDataStateDialog}
          defaultCarData={defaultCarData}
          finishAction={addData}
          steps={['กรอกข้อมูลรถ', 'ตัวอย่าง', 'กำลังเพิ่มรถเข้าสู่ระบบ']}
          allBrand={allBrand}
        />
      </>) : ''}
    </div>
  </div>);
};

// const mapStateToProps = createStructuredSelector({
//   cars: selectCollectionSortDesc,
//   user: selectCurrentUser,
// });

const mapStateToProps = (state, ownerProps) => {
  return {
    cars: selectCollectionSortByIdDesc(state),
    user: selectCurrentUser(state),
    isLoading: ((state) => (state.cars.isFetching))(state),
    allBrand: selectCarAllBand(state),
  }
};

const mapDispathToProps = (dispatch) => ({
  addCollectionAsync: carData => dispatch(addCollectionAsync(carData)),
});

export default connect(mapStateToProps, mapDispathToProps)(CarsPage);
