import axios from 'axios';
import {enableFirebase} from '../firebase/firebase.util';

export const lineNotify = (message) => {
  if(enableFirebase){
    console.log('lineNotify:message', message)
    const url = 'https://asia-northeast1-doyo-autocar.cloudfunctions.net/lineNotify';
    // const url = 'http://localhost:5000/doyo-autocar/asia-northeast1/lineNotify';
    return axios.get(url+'/'+encodeURI(message));
  }else{
    return Promise.resolve();
  }
};