import CarActionTypes from './car.types';
import { firestore, convertCollectionsSnapshotToMap, updateCollectionByIdx,
  firebaseDatabaseName, addCollectionAndDocuments, removeCollectionByIdx,
  enableFirebase } from '../../firebase/firebase.util';

  export const loadAllBand = (payload) => ({
    type: CarActionTypes.LOAD_ALL_BAND,
    payload
  });

export const setSearchText = (payload) => ({
  type: CarActionTypes.SET_SEARCH_TEXT,
  payload
});

export const addNewCars = (payload) => ({
  type: CarActionTypes.ADD_NEW_CAR,
  payload
});

export const editNewCars = (payload) => ({
  type: CarActionTypes.EDIT_CAR,
  payload
});

export const removeCar = (payload) => ({
  type: CarActionTypes.REMOVE_CAR,
  payload
});

export const fetchCollectionStart = () => ({
  type: CarActionTypes.FETCH_COLLECTIONS_START
});

export const fetchCollectionSuccess = (collectionsMap) => ({
  type: CarActionTypes.FETCH_COLLECTIONS_SUCCESS,
  payload: collectionsMap
});

export const resetState = () => ({
  type: CarActionTypes.RESET_STATE,
});

export const fetchCollectionFailure = (errMsg) => ({
  type: CarActionTypes.FETCH_COLLECTIONS_FAILURE,
  payload: errMsg
});

export const fetchCollectionStartAsync = () => {
  return dispatch => {
    if(!enableFirebase) return;

    const collectionRef = firestore.collection(firebaseDatabaseName);
    dispatch(fetchCollectionStart());

    collectionRef.get().then((snapshot) => {
      const collectionMap = convertCollectionsSnapshotToMap(snapshot);
      dispatch(fetchCollectionSuccess(collectionMap));
    }).catch((err) => {
      dispatch(fetchCollectionFailure(err.message));
    });
  };
};

export const removeCollectionAsync = (idx) => {
  return dispatch => {
    removeCollectionByIdx(firebaseDatabaseName, idx).then((snapshot) => {
      dispatch(removeCar(idx));
    }).catch((err) => {
      dispatch(fetchCollectionFailure(err.message));
    });
  };
}

export const updateCollectionAsync = (obj) => {
  return dispatch => {
    updateCollectionByIdx(firebaseDatabaseName, obj.idx, obj).then((snapshot) => {
      dispatch(editNewCars(obj));
    }).catch((err) => {
      dispatch(fetchCollectionFailure(err.message));
    });
  };
}

export const addCollectionAsync = (obj) => {
  return dispatch => {
    addCollectionAndDocuments(firebaseDatabaseName, [obj]).then((snapshot) => {
      dispatch(addNewCars(obj));
    }).catch((err) => {
      dispatch(fetchCollectionFailure(err.message));
    });
  };
}
