import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CallIcon from '@material-ui/icons/Call';
import FacebookIcon from '@material-ui/icons/Facebook';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import { Box } from '@material-ui/core';

import SellCarDialog from './dialog.component';
import LineIcon from '../custom-icons/line.components';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  fab: {
    position: "fixed",
    right: 5,
    bottom: 80,
  },
}));

const SellCarForm = ({ formData, setFormData }) => {
  const classes = useStyles();

  const handleClickOpen = () => {
    setFormData({
      ...formData,
      open: true,
    });
  };

  const handleClose = () => {
    setFormData({
      ...formData,
      open: false,
    });
  };

  const handleNameChange = (event) => {
    const value = event.target.value;
    setFormData({
      ...formData,
      name: value,
    });
  };

  const handlePhoneChange = (event) => {
    const value = event.target.value;
    setFormData({
      ...formData,
      phone: value,
    });
  };

  const handleLineChange = (event) => {
    const value = event.target.value;
    setFormData({
      ...formData,
      line: value,
    });
  };

  const handleFacebookChange = (event) => {
    const value = event.target.value;
    setFormData({
      ...formData,
      facebook: value,
    });
  };

  const handlePriceLowChange = (event) => {
    const value = event.target.value;
    setFormData({
      ...formData,
      priceLow: value,
    });
  };

  const handlePriceHightChange = (event) => {
    const value = event.target.value;
    setFormData({
      ...formData,
      priceHight: value,
    });
  };

  const handleDetailChange = (event) => {
    const value = event.target.value;
    if (value.length < formData.maxDetailsLength) {
      setFormData({
        ...formData,
        detail: value,
      });
    }
  };

  const fields = [
    {
      id: 'name',
      title: 'ชื่อ - นามสกุล',
      icon: <AccountCircle />,
      variant: 'filled',
      isRequire: true,
      onChange: handleNameChange,
    },
    {
      id: 'phone',
      title: 'เบอร์โทรติดต่อกลับ',
      icon: <CallIcon />,
      variant: 'filled',
      isRequire: true,
      placeholder: 'e.g. 080 123 4567',
      onChange: handlePhoneChange,
    },
    {
      id: 'line',
      title: 'Line',
      icon: <LineIcon height={25} width={25} bgColor={'#000'} />,
      isRequire: false,
      placeholder: 'line id, e.g. @bew6965',
      onChange: handleLineChange,
    },
    {
      id: 'facebook',
      title: 'Facebook',
      icon: <FacebookIcon />,
      isRequire: false,
      placeholder: 'ชื่อ facebook, id, e.g. Doyoautocar',
      onChange: handleFacebookChange,
    }
  ];

  return (
    <div>
      <Grid container spacing={3}>
        {fields.map(f => {
          return (<Grid item xs={12} md={6} key={f.id}>
            <TextField
              id={f.id}
              required={f.isRequire}
              fullWidth
              label={f.title}
              placeholder={f.placeholder || f.title}
              onChange={f.onChange}
              InputProps={{
                startAdornment: <InputAdornment position="start">{f.icon}</InputAdornment>,
              }}
              variant={f.variant ? f.variant : 'standard'} />
          </Grid>);
        })}

        <Grid item xs={12}>
          <Divider /><br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h2>ราคาที่คาดหวัง</h2>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='price-low'
                fullWidth
                type="number"
                label="ราคาต่ำสุด"
                onChange={handlePriceLowChange}
                variant="standard" />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='price-hight'
                fullWidth
                type="number"
                label="ราคาสูงสุด"
                onChange={handlePriceHightChange}
                variant="standard" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <br />
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box textAlign="left">
                <h2>กรุณากรอกรายละเอียดรถที่จะขาย เช่น ยี่ห้อรถ รุ่นรถ ปีอะไร optionตัวไหน เกียร์อะไร เครื่องเท่าไหร่ รถสภาพเป็นอย่างไร เป็นต้น </h2>
                ({formData.detail.length}/{formData.maxDetailsLength})
              </Box>
            </Grid>
            <Grid item xs={12}><TextField
              fullWidth
              label="รายละเอียดรถ"
              multiline
              value={formData.detail}
              rows={8}
              variant="outlined"
              onChange={handleDetailChange}
            /></Grid>
          </Grid>
        </Grid>
      </Grid>

      <Fab
        color="primary"
        aria-label="send"
        className={classes.fab}
        onClick={handleClickOpen}
      >
        <SendIcon />
      </Fab>
      <SellCarDialog formData={{ ...formData }} handleClose={handleClose} />
    </div>
  );
};

export default SellCarForm;