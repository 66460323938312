import React from 'react';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import SimpleBackdrop from '../backdop/backdop.component';
// import Image from 'material-ui-image';
import { formatMoney } from '../common-utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    'flex-direction': 'column',
    'border-radius': '5px',
    cursor: 'pointer',
    '-moz-osx-font-smoothing': 'grayscale',
    'backface-visibility': 'hidden',
    transform: 'translateZ(0)',
    transition: 'transform 0.25s ease-out',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  media: {
    'max-width': '100%',
    height: '250px',
  },
  centerCropped: {
    'background-position': 'center center',
    'background-repeat': 'no-repeat',
  },
  mediaImage: {
    maxWidth: '100%',
    height: 'auto',
  },
}));


export const CardCar = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const timer = React.useRef();

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClick = () => {
    handleToggle();
    const getUrl = window.location;
    const baseUrl = getUrl.protocol + "//" + getUrl.host;
    const url = baseUrl + '/car/' + props.car.id;
    // window.location.href = url;
    window.open(url, "_blank");
    timer.current = setTimeout(() => {
      handleClose();
    }, 1000);
  }

  return (
    <div>
      <Card className={classes.root}>
        <CardActionArea onClick={handleClick} id={props.car.carId}>
          <CardMedia
            className={clsx(classes.centerCropped, classes.media)}
            image={props.car.images[0]}
            alt="Contemplative Reptile"
            src='img'
            component="img"
          />
          {/* <Image className={clsx(classes.centerCropped, classes.mediaImage)} src={props.car.images[0]} /> */}
          <CardContent style={{ padding: '6px' }}>
            <Typography gutterBottom variant="h5" component="h2">
              {props.car.name}{' '}
              {props.car.date ? (<b>[{props.car.date}]</b>) : ''}{' '}
              {props.car.financial.header ? (<b><br />{formatMoney(props.car.financial.header)}</b>) : ''}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <SimpleBackdrop
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
};
