import React from 'react';
import PropTypes from 'prop-types';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { CardMedia } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Hidden from '@material-ui/core/Hidden';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { formatMoney } from '../common-utils.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansionPanelSummaryContent: {
    display: 'block'
  }
}));

const changeUrl = (path) => {
  const getUrl = window.location;
  const baseUrl = getUrl.protocol + "//" + getUrl.host;
  window.location.href = baseUrl + path;
};

function ScrollDown(props) {
  const { children } = props;
  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-buttom-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <div onClick={handleClick} role="presentation">
      {children}
    </div>
  );
}

ScrollDown.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const CarDetailsComponents = ({ carData, isEdit }) => {
  const classes = useStyles();
  const [imgState, setImgState] = React.useState({
    modalIsOpen: false,
    selectIndex: 0
  });

  if (!carData) {
    return (<></>);
  }
  let imageCount = carData.images.length;

  const specDetails = [
    {
      name: 'รุ่น',
      value: carData.model
    },
    {
      name: 'ปี',
      value: carData.date
    },
    {
      name: 'เกียร์',
      value: carData.transmissionType
    },
    {
      name: 'ขนาดเครื่องยนต์',
      value: carData.engineSize
    },
    {
      name: 'ชนิดเครื่องยนต์',
      value: carData.engineType
    }
  ];

  const lastLabel = 'ออกรถใช้เงิน';

  const priceLabel = (name, index) => {
    const price = formatMoney(carData.financial.rows[name]);
    if (!price) return '';

    return [
      <Grid
        item
        xs={12}
        md={6}
        key={index.toString() + name}
      >
        <Box
          textAlign="left"
          fontWeight="fontWeightBold">{name}</Box>
        <Hidden smDown implementation="css"><Divider /></Hidden>
      </Grid>,
      < Grid item xs={12} md={6} key={index.toString() + carData.financial.rows[name]} >
        <Box textAlign="right">{price}</Box>
        {(!carData.financial.rows[name]) ? '' : (<Divider />)}
      </Grid>
    ];
  };

  const details = (<ExpansionPanel defaultExpanded>
    <ExpansionPanelSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1bh-content"
      id="panel1bh-header"
    >
      <Box>
        <Box fontWeight="fontWeightBold" className={classes.heading}>สเปคและรายละเอียด</Box>
      </Box>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <Grid container spacing={3}>
        {specDetails.filter(data => data.value).map((data, index) => {
          return [
            <Grid
              item
              xs={12}
              md={6}
              key={index.toString() + data.name}
            >
              <Box
                textAlign="left"
                fontWeight="fontWeightBold">{data.name}</Box>
              <Hidden smDown implementation="css"><Divider /></Hidden>
            </Grid>,
            <Grid item xs={12} md={6} key={index.toString() + data.value}>
              <Box textAlign="right">{data.value}</Box>
              {data.value ? (<Divider />) : ''}
            </Grid>
          ];
        })}
      </Grid>
    </ExpansionPanelDetails>
  </ExpansionPanel>);

  const pricing = (<ExpansionPanel defaultExpanded>
    <ExpansionPanelSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel2bh-content"
      id="panel2bh-header"
      classes={{
        content: classes.expansionPanelSummaryContent
      }}
    >
      <Box>
        <Box fontWeight="fontWeightBold" className={classes.heading}>ราคา {formatMoney(carData.financial.header)} บาท</Box>
      </Box>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          md={6}
          key='x0'
        >
          <Box
            textAlign="left"
            fontWeight="fontWeightBold">ยอดผ่อนชำระ / เดือน(บาท)</Box>
        </Grid>
        < Grid item xs={12} md={6} key='x1' >
        </Grid>
        {Object.keys(carData.financial.rows).filter(n => n !== lastLabel).map((name, index) => {
          if (!name || name === '-' || carData.financial.rows[name] === '-' || name.trim() === 'ยอดผ่อนชำระ/เดือน(บาท)') {
            return '';
          } else {
            return priceLabel(name, index);
          }
        })}
        {Object.keys(carData.financial.rows).filter(n => n === lastLabel).map((name, index) => {
          return priceLabel(name, index);
        })}
      </Grid>
    </ExpansionPanelDetails>
  </ExpansionPanel >);

  // const [modalIsOpen, setModalIsOpen] = React.useState(false);
  // const [selectIndex, setSelectIndex] = React.useState(0);

  const toggleModal = () => {
    // setModalIsOpen(!modalIsOpen);
    setImgState({
      ...imgState,
      modalIsOpen: !imgState.modalIsOpen
    });
  }

  const selectImage = (index) => {
    // setSelectIndex(index);
    setImgState({
      ...imgState,
      modalIsOpen: true,
      selectIndex: index
    });
  }

  return (<Grid container spacing={1}>
    {isEdit ? '' : (<ModalGateway>
      {imgState.modalIsOpen ? (
        <Modal onClose={toggleModal}>
          <Carousel
            views={carData.images.map(img => {
              return {
                source: img
              };
            })}
            currentIndex={imgState.selectIndex}
          />
        </Modal>
      ) : null}
    </ModalGateway>)}
    {carData.images.map((img, index) => {
      return (
        <Grid item md={12} lg={6} key={index}>
          <Paper className={classes.paper} style={{ cursor: 'pointer' }}>
            <CardMedia
              image={img}
              alt="Contemplative Reptile"
              src='img'
              component='img'
              onClick={isEdit ? undefined : () => selectImage(index)}
            />
          </Paper>
        </Grid>);
    })}
    {carData.images.length % 2 !== 0 ? (<Grid item xs={12}></Grid>) : ''}
    <Grid item xs={12} key={++imageCount}>
      {details}
    </Grid>
    {carData.description ? (<Grid item xs={12} key='description'>
      <Paper className={classes.paper}>
        <Box fontSize={20} textAlign='left'>{carData.description}</Box>
      </Paper>
    </Grid>) : ''}
    <Grid item xs={12} key={++imageCount}>
      {pricing}
    </Grid>
    <Grid item xs={12} key={++imageCount}>
      <Paper className={classes.paper}>
        <Box fontSize={16} textAlign='left' fontWeight='fontWeightBold'>*** ราคาผ่อนอาจมีการเปลี่ยนแปลง โปรดติดต่อทางเต็นท์ โดยตรง ***</Box>

      </Paper>
    </Grid>
    <Grid item xs={12} key={++imageCount}>
      <Button variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          if (!isEdit) {
            changeUrl('/contact')
          }
        }}
      >
        ติดต่อผู้ขาย
        </Button>
    </Grid>

  </Grid>);
};

export default CarDetailsComponents;
