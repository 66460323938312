import CarActionTypes from './car.types';
import {filteredByNameOrBrandCollection} from './car.utils';
// import CARS_DATA from './car.data';
import BAND_DATA from './band.data';
import moment from 'moment';

const INITIAL_STATE = {
  carsOrg: [],
  cars: [],
  search: '',
  isFetching: false,
  errorMessage: undefined,
  allBand: BAND_DATA,
  lastUpdate: undefined,
};

const carReducer = (state = INITIAL_STATE, action) => {  
  switch(action.type){
    case CarActionTypes.LOAD_ALL_BAND: {  
      return {
        ...state,
        allBand: BAND_DATA,
      };
    }
    case CarActionTypes.SET_SEARCH_TEXT: {
      return {
        ...state,
        search: action.payload,
        cars: filteredByNameOrBrandCollection(state.carsOrg, action.payload.search)
      };
    }
    case CarActionTypes.ADD_NEW_CAR: {
      return {
        ...state,
        carsOrg: [...state.carsOrg, action.payload],
        cars: [...state.cars, action.payload],
      };
    }
    case CarActionTypes.GET_CAR_BY_ID: {
      return {
        ...state
      };
    }
    case CarActionTypes.GET_BAND_ALL: {
      return {
        ...state
      };
    }
    case CarActionTypes.EDIT_CAR: {
      const editCarData = action.payload;
      const { carsOrg, cars } = state;
      const findCarOrg = carsOrg.findIndex(c => c.id === editCarData.id);
      const findCars = cars.findIndex(c => c.id === editCarData.id);

      // replace car data
      if(findCarOrg > -1){
        carsOrg[findCarOrg] = editCarData;
      }
      
      if(findCars > -1){
        cars[findCars] = editCarData;
      }

      return {
        ...state,
        carsOrg,
        cars
      };
    }
    case CarActionTypes.REMOVE_CAR: {
      const idx = action.payload;
      const { carsOrg, cars } = state;

      return {
        ...state,
        carsOrg: carsOrg.filter(car => car.idx !== idx),
        cars: cars.filter(car => car.idx !== idx),
      }
    }
    case CarActionTypes.FETCH_COLLECTIONS_START: {
      return {
        ...state,
        isFetching: true
      };
    }
    case CarActionTypes.RESET_STATE: {
      return INITIAL_STATE;
    }
    case CarActionTypes.FETCH_COLLECTIONS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        lastUpdate: moment().format('YYYY-MM-DD HH:mm').toString(),
        carsOrg: action.payload,
        cars: action.payload,
      };
    }
    case CarActionTypes.FETCH_COLLECTIONS_FAILURE: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      };
    }
    default:
      return state;
  }
}

export default carReducer;
