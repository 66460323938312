import React from 'react';
import { Route } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import HomePage from './home.pages';
import { getTitle } from '../../components/common-utils';

import { fetchCollectionStartAsync, resetState } from '../../redux/car/car.actions';
import { PAGE_TITLE } from '../../components/common-utils';
import { enableFirebase } from '../../firebase/firebase.util';

class HomeNevigatePage extends React.Component {

  componentDidMount() {
    const pageTitle = getTitle(window.location.pathname);
    const { fetchCollectionStartAsync /*,selectCollections*/ } = this.props;

    if (enableFirebase) {
      this.props.resetState();
    }

    if (pageTitle === PAGE_TITLE.HOME) {
      console.log('fetchCollectionStartAsync', fetchCollectionStartAsync());
    }
  }

  render() {

    return (<div>
      <Route path='' component={HomePage} />
    </div>);
  }
}

const mapStateToProps = createStructuredSelector({
  // selectCollections: selectCollections,
});

const mapDispatchToProps = dispatch => ({
  fetchCollectionStartAsync: () => dispatch(fetchCollectionStartAsync()),
  resetState: () => dispatch(resetState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeNevigatePage);
