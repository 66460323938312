import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Backdrop from '@material-ui/core/Backdrop';
import SimpleBackdrop from '../../components/backdop/backdop.component';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog';
import CarDetailsComponents from '../../components/car-details/car-details.components';
import { selectCollection, selectCarAllBand } from '../../redux/car/car.selectors';
import useStylesCommon from '../../components/common.styles';
import { selectCurrentUser } from '../../redux/user/user.sectors';
import FormDialog from '../../components/add-dialog/add-dialog.components';
import { updateCollectionAsync, removeCollectionAsync } from '../../redux/car/car.actions';
import { checkLoginAdmin } from '../../components/common-utils';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabEdit: {
    bottom: theme.spacing(2)
  },
  rootSpeedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
}));

const CarByIdPage = (state) => {
  const { selectCollection, user, updateCollectionAsync, removeCollectionAsync, allBrand } = state;
  const classes = useStyles();
  const classesCommon = useStylesCommon();
  const timerRef = React.useRef();

  // const carData = selectCollection(match.params.carId)(state);
  const carData = selectCollection;
  const [open, setOpen] = React.useState(false);
  const [carDataDialog, setCarDataStateDialog] = React.useState(carData);
  const removeImage = (images, iconIsRemoveImg) => {
    const imagesTemp = [];
    // remove previous imagesTemp
    iconIsRemoveImg.forEach((isRemove, index) => {
      if (!isRemove) {
        imagesTemp.push(images[index]);
      }
    });
    return imagesTemp;
  };

  const handlePreviewImage = (images, receiveState) => {
    return removeImage(images, receiveState.iconIsRemoveImg);
  };

  const updateData = (data, receiveState) => {
    // data.images = removeImage(data, receiveState.iconIsRemoveImg);
    data.images = receiveState.previewImage;
    updateCollectionAsync(data);
  };

  const isAdmin = user
    && user.currentUser
    && user.currentUser.username
    && user.currentUser.password ?
    checkLoginAdmin(user.currentUser.username, user.currentUser.password)
    : false;

  // loading
  const [loading, setloading] = React.useState(false);
  const handleCloseLoading = () => {
    setloading(false);
  };

  const handleToggleLoading = () => {
    setloading(!open);
  };

  // confirm dialog
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const handleClickOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmAction = () => {
    console.log('Confirm delete this car');
    handleCloseConfirmDialog();
    handleToggleLoading();

    removeCollectionAsync(carData);

    // TODO: remove simulate
    timerRef.current = setTimeout(() => {
      state.history.push(`/`);
    }, 1000);
  };

  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);

  const handleOpenDial = () => {
    setOpenSpeedDial(true);
  };

  const handleCloseSpeedDial = () => {
    setOpenSpeedDial(false);
  };


  return (<div className={classesCommon.appBar}>
    <CarDetailsComponents carData={carData} />
    {isAdmin ? (<>
      <div className={clsx(classes.fab)}>
        <Backdrop open={openSpeedDial} />
        <SpeedDial
          ariaLabel="SpeedDial tooltip"
          icon={<SpeedDialIcon />}
          onClose={handleCloseSpeedDial}
          onOpen={handleOpenDial}
          open={openSpeedDial}
        >
          <SpeedDialAction
            key={'edit'}
            icon={(<EditIcon />)}
            tooltipTitle={'แก้ไข'}
            tooltipOpen
            onClick={() => { setOpen(true) }}
          />
          <SpeedDialAction
            key={'remove'}
            icon={(<DeleteIcon />)}
            tooltipTitle={'ลบ'}
            tooltipOpen
            onClick={handleClickOpenConfirmDialog}
          />
        </SpeedDial>
      </div>
      <ConfirmDialog
        open={openConfirmDialog}
        handleClose={handleCloseConfirmDialog}
        handleConfirm={handleConfirmAction}
        title={'คุณต้องการจะลบรถคันนี้(' + (carData && carData.name ? carData.name : '') + ')  ออกจากระบบหรือไม่ ?'}
        description={'การลบครั้งนี้จะไม่สามารถแก้ไข หรือย้อนกลับได้'}
      />
      <FormDialog
        key='edit-cars-dialog'
        open={open}
        setOpen={setOpen}
        carData={carDataDialog}
        setCarDataState={setCarDataStateDialog}
        defaultCarData={carData}
        finishAction={updateData}
        steps={['แก้ไขข้อมูลรถ', 'ภาพตัวอย่าง', 'กำลังแก้ไข']}
        title='แก้ไขข้อมูลรถ'
        handlePreviewImage={handlePreviewImage}
        allBrand={allBrand}
      />

      <SimpleBackdrop
        open={loading}
        handleClose={handleCloseLoading}
      />
    </>) : ''}
    <Box id="back-to-buttom-anchor" padding={8}></Box>
  </div>);
};

const mapStateToProps = (state, ownerProps) => {
  return {
    selectCollection: selectCollection(ownerProps.match.params.carId)(state),
    user: selectCurrentUser(state),
    allBrand: selectCarAllBand(state),
  };
};

const mapDispathToProps = (dispatch) => ({
  updateCollectionAsync: carData => dispatch(updateCollectionAsync(carData)),
  removeCollectionAsync: carData => dispatch(removeCollectionAsync(carData.idx)),
});

export default connect(mapStateToProps, mapDispathToProps)(CarByIdPage);
