import React from 'react';

const FacebookPageComponents = (props) => {
  return (
    <div
      className="fb-page"
      data-href="https://www.facebook.com/Doyoautocar/"
      data-tabs="timeline"
      data-width="500"
      data-height=""
      data-small-header="false"
      data-adapt-container-width="true"
      data-hide-cover="false"
      data-show-facepile="true">
      <blockquote
        cite="https://www.facebook.com/Doyoautocar/"
        className="fb-xfbml-parse-ignore">
        <a href="https://www.facebook.com/Doyoautocar/">Doyo Auto Car โดโย่ ออโต้คาร์ ซื้อ-ขาย-แลกเปลี่ยน รถยนต์มือสองทุกชนิด</a>
      </blockquote>
    </div>
  );
};

export default FacebookPageComponents