import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import Hidden from '@material-ui/core/Hidden';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import Alert from '@material-ui/lab/Alert';

import { formatMoney } from '../common-utils';
import { lineNotify } from '../../line/line.utils';

const formatLineMessage = (formData) => {
  return '\nชื่อ: ' + formData.name + ",\n"
    + 'เบอร์: ' + formData.phone + ",\n"
    + (formData.line ? 'line: ' + formData.line + ",\n" : '')
    + (formData.facebook ? 'fb: ' + formData.facebook + ",\n" : '')
    + ((formData.priceLow || formData.priceHight) ? 'ราคาที่คาดหวัง: ' + formatMoney(formData.priceLow) + ' - ' + formatMoney(formData.priceHight) + ",\n" : '')
    + (formData.detail ? 'รายละเอียดรถ: `' + formData.detail + '`' : '');
};

const SellCarDialog = ({ formData, handleClose }) => {
  const [isSuccess, setIsSuccess] = React.useState({
    status: false,
    message: '',
    loading: false,
    finish: false,
  });

  const timerRef = React.useRef();
  const handleSendLine = () => {
    setIsSuccess({
      ...isSuccess,
      loading: true,
      finish: false,
    });

    timerRef.current = setTimeout(() => {
      lineNotify(formatLineMessage(formData)).then(response => {
        setIsSuccess({
          ...isSuccess,
          status: true,
          message: ''
        });
      }).catch(err => {
        setIsSuccess({
          ...isSuccess,
          status: false,
          message: err.message,
        });
      }).finally(() => {
        // timerRef.current = setTimeout(() => {
        //   window.location.reload(false);
        // }, 1000);

        setIsSuccess({
          ...isSuccess,
          loading: false,
          finish: true,
        });


        // handleClose();
      });
    }, 1000);
  };

  const data = [
    {
      value: formData.name,
      label: 'ชื่อ-สกุล',
      icon: '',
    },
    {
      value: formData.phone,
      label: 'เบอร์โทร',
      icon: '',
    },
    {
      value: formData.line,
      label: 'ไลน์',
      icon: '',
    },
    {
      value: formData.facebook,
      label: 'เฟสบุ๊ค',
      icon: '',
    },
    {
      value: formData.priceLow,
      label: 'ราคาที่ต้องการ ต่ำสุด',
      icon: '',
    },
    {
      value: formData.priceHight,
      label: 'ราคาที่ต้องการ สูงสุด',
      icon: '',
    },
    {
      value: formData.detail,
      label: 'รายละเอีดรถ',
      icon: '',
      multiline: true,
      rows: 8,
    }
  ];

  const validate = () => {
    return formData.name && formData.phone && formData.phone.match(/^0+\d{8,9}$/);
  };

  const isValidate = validate();

  const ActionBtn = () => {
    return (<DialogActions>
      <Button onClick={handleClose} color="primary">{isSuccess.finish ? 'ปิด' : 'ยกเลิก'}</Button>
      {isSuccess.finish ? (<></>) : (<Button
        onClick={handleSendLine}
        color="primary"
        variant="contained"
        disabled={isSuccess.loading || !isValidate}
      >ยืนยัน</Button>)}
    </DialogActions>);
  };

  return (
    <Dialog
      open={formData.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      key='sell-car-dialog'
    >
      <DialogTitle id="alert-dialog-title">
        {"ยืนยันการส่งข้อความ"}
        {isSuccess.loading ? (<LinearProgress color="secondary" />) : ''}
      </DialogTitle>
      <Hidden smUp implementation="css">
        <ActionBtn />
      </Hidden>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {isSuccess.status ? (<Alert severity="success">ส่งคำขอสำเร็จ</Alert>) : ''}

          {!isValidate ? (<Alert severity="error">
            กรุณาใส่ <strong>ชื่อ - นามสกุล</strong> และ <strong>เบอร์โทรติดต่อกลับ</strong> ให้ถูกต้องด้วยค่ะ
          </Alert>) : ''}

          {!isSuccess.status && isSuccess.message ? (<Alert severity="error">ส่งคำขอไม่สำเร็จ: {isSuccess.message}</Alert>) : ''}
        </DialogContentText>

        {isSuccess.finish ? (<Alert severity="success">ส่งคำขอเสร็จสิ้น</Alert>) : (
          <Grid container spacing={3}>
            {data.map((d, index) => {
              return (<Grid item xs={12} key={'sell-car-preview-' + index}>
                <TextField
                  fullWidth
                  multiline={d.multiline}
                  rows={d.rows}
                  label={d.label}
                  value={d.value}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{d.icon}</InputAdornment>,
                    readOnly: true,
                  }}
                  variant="outlined" />
              </Grid>);
            })}
          </Grid>
        )}

      </DialogContent>
      <Hidden xsDown implementation="css">
        <ActionBtn />
      </Hidden>
    </Dialog >
  );
};

export default SellCarDialog;
