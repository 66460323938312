import React from 'react';
import { version } from '../../../package.json';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import clsx from 'clsx';
import md5 from 'md5';
import moment from 'moment';

// import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { setCurrentUser } from '../../redux/user/user.actions';
import { selectCurrentUser } from '../../redux/user/user.sectors';
import { selectCollections } from '../../redux/car/car.selectors';

import { checkLoginAdmin } from '../common-utils';

// import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rootAlert: {
    width: '100%'
  },
  iconAdmin: {
    color: theme.palette.secondary.light
  },
  menuRight: {
    right: theme.spacing(2)
  },
  menuAbsolute: {
    position: "absolute"
  },
  adminBtn: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const versionText = 'Version: ' + version;

const LoginDialog = (props) => {
  const { setCurrentUser, currentUser } = props;
  const currentUserObj = (currentUser && currentUser.currentUser) ? currentUser.currentUser : undefined;
  const isAdmin = currentUserObj
    && currentUserObj.username
    && currentUserObj.password ?
    checkLoginAdmin(currentUserObj.username, currentUserObj.password)
    : false;

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const timer = React.useRef();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let username;
  let password;

  const handleClickExport = () => {
    const blob = new Blob([JSON.stringify(props.allCar, null, 2)], { type: 'application/json' });

    const csvURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `all-cars-${moment()}.json`);
    tempLink.click();
  };

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleChangeUsername = (event) => {
    username = event.target.value;
    event.preventDefault();
  };

  const handleChangePassword = (event) => {
    password = event.target.value;
    event.preventDefault();
  };

  const alertHandle = (status) => {
    setAlert(status);

    // auto hide after 1s
    if (status) {
      timer.current = setTimeout(() => {
        setAlert(false);
      }, 1000);
    }
  };

  const processAdminLogin = () => {
    setLoading(true);

    // TODO: remove mock
    timer.current = setTimeout(() => {
      // TODO: remove mock data
      const pwd = md5(password);
      if (checkLoginAdmin(username, pwd)) {
        setCurrentUser({
          currentUser: {
            id: '0000',
            name: 'Admin',
            username,
            password: pwd
          }
        });
        // handleCloseDialog(); // SUCCESS
      } else {
        // TODO: remove mock
        alertHandle(true); // ERROR
      }
      setLoading(false);
    }, 1000);
  };

  const handleClickLogout = (event) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
  };

  const handleCloseLogout = () => {
    setAnchorEl(null);
  };

  const processAdminLogout = () => {
    console.log('processAdminLogout');
    handleCloseLogout();
    setLoading(true);

    // TODO: remove mock
    timer.current = setTimeout(() => {
      // TODO: remove mock data
      setCurrentUser({
        currentUser: undefined
      });
      setLoading(false);

      // TODO: remove mock
      // handleCloseDialog(); // SUCCESS
    }, 1000);
  };

  return (
    <div className={clsx(classes.menuRight, (props.isSearch ? undefined : classes.menuAbsolute), classes.adminBtn)}>
      <IconButton
        aria-label="show more"
        aria-controls="primary-search-account-menu-mobile"
        aria-haspopup="true"
        onClick={handleClickOpenDialog}
        color="inherit"
      >
        <AccountCircle className={isAdmin ? classes.iconAdmin : ''} />
      </IconButton>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Admin login <Chip
            label={versionText}
            color="primary"
            variant="outlined"
          /></DialogTitle>
        {loading && <LinearProgress variant="query" />}
        <DialogContent>
          {isAdmin ? (<Chip
            label={currentUserObj.username}
            color="secondary"
            variant="outlined"
          />) : (<TextField
            margin="dense"
            id="email"
            label="Email Address"
            onChange={handleChangeUsername}
            disabled={loading}
            fullWidth
            required
          />)}
          {isAdmin ? '' : (<TextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            onChange={handleChangePassword}
            disabled={loading}
            fullWidth
            required
          />)}
        </DialogContent>
        <DialogActions>
          <Fade in={alert} className={classes.rootAlert}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    alertHandle(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity="error"
            >
              เข้าสู่ระบบไม่สำเร็จ
            </Alert>
          </Fade><br />
          {/* <Button onClick={handleCloseDialog} disabled={loading}>Cancel</Button> */}
          {isAdmin ? (
            <>
              <Button
                variant="outlined"
                onClick={handleClickExport}
              >
                Export
              </Button>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClickLogout}
                // color="secondary"
                style={{ color: 'red' }}
                variant="outlined"
                disabled={loading}>Logout</Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseLogout}
              >
                <MenuItem onClick={processAdminLogout}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
              <Button onClick={processAdminLogin} color="primary" variant="outlined" disabled={loading} autoFocus>Login</Button>
            )}
        </DialogActions>
      </Dialog>
    </div>);
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  allCar: selectCollections,
});

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(setCurrentUser(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginDialog);