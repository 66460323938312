import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { CardCarDetail } from '../../components/card/card-detail.component';
import { selectCollectionSortDescByBrand } from '../../redux/car/car.selectors';
import useStylesCommon from '../../components/common.styles';
import { getTitle } from '../../components/common-utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  }
}));

const CarsBrandPage = (props) => {
  const classes = useStyles();
  const classesCommon = useStylesCommon();

  const { carsFiltered, brand } = props;

  const [searchCars, setSearchCars] = React.useState([...carsFiltered]);

  const onSearchChange = event => {
    // this.setState({ searchField: event.target.value });
    const searchText = event.target.value.trim().toLowerCase();

    if (searchText) {
      setSearchCars(carsFiltered.filter(car => car.name.toLowerCase().includes(searchText)));
    } else {
      setSearchCars([...carsFiltered]);
    }
  };

  return (<div className={clsx(classesCommon.appBar, classes.root)}>
    <Grid className={classes.grid} container spacing={3}>
      <Grid item xs={12}>
        <TextField
          id="search-car-brand"
          label={'ค้นหารถยี่ห้อ ' + brand}
          fullWidth
          onChange={onSearchChange}
          variant="outlined" />
      </Grid>
      {searchCars ? searchCars.map(car => (
        <Grid item xs={12} md={4} sm={6} key={car.id}>
          <CardCarDetail car={car} />
        </Grid>
      )) : ''}
    </Grid>
  </div>);
};

const mapStateToProps = (state, ownerProps) => {
  const pageTitle = getTitle(window.location.pathname);

  return {
    carsFiltered: selectCollectionSortDescByBrand(pageTitle)(state),
    brand: pageTitle,
  };
};

const mapDispathToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispathToProps)(CarsBrandPage);
