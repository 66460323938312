import moment from 'moment';
import numeral from 'numeral';
import md5 from 'md5';

const adminCredential = {
  username: 'doyoautocar.w@gmail.com',
  password: md5('HXc6t$DiESjxT8BNL807up$h4L@3oB')
}

export const PAGE_TITLE = {
  HOME: 'หน้าแรก',
  ALL_CAL: 'รถยนต์ทั้งหมด',
  CONTACT: 'ติดต่อเรา',
  CAR_SELL: 'ติดต่อขายรถ',
}

export const getTitle = (path) => {
  if(path.indexOf('/car/') === 0){
    return 'car_id:'+path.replace('/car/', '');
  }else if(path.indexOf('/brand/cars/') === 0) {
    return path.replace('/brand/cars/', '');
  }else{
    switch (path) {
      case '/contact':
        return PAGE_TITLE.CONTACT;
        case '/sell-car':
          return PAGE_TITLE.CAR_SELL;
        case '/car':
        return PAGE_TITLE.ALL_CAL;
      default: {
        return PAGE_TITLE.HOME;
      }
    }
  }
};

export const imageFileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const checkLoginAdmin = (username, password) => {
  return username === adminCredential.username && password === adminCredential.password;
}

export const carDataTemplate = (lastId) => {
  const currentDate = moment().format('YYYY-MM-DDThh:mm:ss');
  return {
    "id": (lastId + 1).toString(),
    "createDate": currentDate,
    "updateDate": currentDate,
    "name": "",
    "brand": "",
    "description": "",
    "date": "",
    "model": "",
    "engineSize": "",
    "engineType": "",
    "transmissionType": "",
    "financial": {
      "header": "",
      "rows": {
        "ยอดผ่อนชำระ/เดือน(บาท)": "",
        "ออกรถใช้เงิน": ""
      }
    },
    "images": []
  };
};

export const formatMoney = (number) => {
  return number ? numeral(number).format('0,0') : number;
}

// export const formatMoney = (number, decPlaces, decSep, thouSep) => {
//   if(!number) {
//     return undefined;
//   }
  
//   decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
//   decSep = typeof decSep === "undefined" ? "." : decSep;
//   thouSep = typeof thouSep === "undefined" ? "," : thouSep;
//   var sign = number < 0 ? "-" : "";
//   var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
//   var j = (j = i.length) > 3 ? j % 3 : 0;

//   return sign +
//     (j ? i.substr(0, j) + thouSep : "") +
//     i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
//     (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
// }
