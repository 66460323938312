import {createSelector} from 'reselect';
import moment from 'moment';

function getUnique(arr, comp) {
  // store the comparison  values in array
const unique =  arr.filter(e => e && e[comp]).map(e => e[comp])

// store the indexes of the unique objects
.map((e, i, final) => final.indexOf(e) === i && i)

// eliminate the false indexes & return unique objects
.filter((e) => arr[e]).map(e => arr[e]);

return unique;
}

const selectCars = state => state.cars;

export const selectCollections = createSelector(
  [selectCars],
  car => car.cars
);

export const selectCarAllBand = createSelector(
  [selectCars],
  car => car.allBand
);

export const selectLastUpdate = createSelector(
  [selectCars],
  car => car.lastUpdate
);

export const selectCarAviableBand = createSelector(
  [selectCars],
  car => {
    return car.allBand ? getUnique(car.carsOrg.map(c => car.allBand.find(band => band.band.toLowerCase().trim() === c.brand.toLowerCase().trim())),'band') : [];
  }
);

export const selectCollectionSortDesc = createSelector(
  [selectCars],
  car => car && car.cars ? car.cars.sort((a, b) => {
    return moment(b.createDate).unix()-moment(a.createDate).unix();
  }) : []
);

export const selectCollectionSortByIdDesc = createSelector(
  [selectCars],
  car => car && car.cars ? car.cars.sort((a, b) => {
    return b.id-a.id;
  }) : []
);

const filteredByBrand = (cars, brand) => {
  const brandFormat = brand.trim().toLowerCase();

  return cars ? cars.filter(car => car.brand && car.brand.trim().toLowerCase() === brandFormat) : [];
  
};

export const selectCollectionSortDescByBrand = brand => {
  return createSelector(
    [selectCollectionSortByIdDesc],
    (cars) => filteredByBrand(cars, brand)
  );
};

export const selectCollectionLastId = createSelector(
  [selectCollectionSortByIdDesc],
  cars => cars.slice(0, 5)
);

export const selectCollection = carId => {
  return createSelector(
    [selectCollections],
    cars => cars ? cars.find(car => car.id === carId) : null
  );
};

export const selectCollectionFetching = createSelector(
  [selectCars],
  cars => cars.isFetching
);
