import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import config from './firebase.config.js';

firebase.initializeApp(config);

export const firebaseDatabaseName = 'cars_collection';

// TODO: change enable firebase
export const enableFirebase = true;

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;

  const userRef = firestore.doc(`users/${userAuth.uid}`);

  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const {
      displayName,
      email
    } = userAuth;
    const createdAt = new Date();
    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData
      });
    } catch (error) {
      console.log('error creating user', error.message);
    }
  }

  return userRef;
};

export const addCollectionAndDocuments = async (collectionKey, objectToAdd) => {
  if(!enableFirebase) return;

  const collectionRef = firestore.collection(collectionKey);
  const batch = firestore.batch();
  objectToAdd.forEach(obj => {
    const newDocRef = collectionRef.doc();
    batch.set(newDocRef, obj);
  });

  return await batch.commit();
};

export const updateCollectionByIdx = async (collectionKey, idx, objectToAdd) => {
  if(!enableFirebase) return;

  const collectionRef = firestore.collection(collectionKey);
  const newDocRef = collectionRef.doc(idx);

  const batch = firestore.batch();
  batch.set(newDocRef, objectToAdd);
  return await batch.commit();
}

export const removeCollectionByIdx = async (collectionKey, idx) => {
  if(!enableFirebase) return;

  const collectionRef = firestore.collection(collectionKey);
  const newDocRef = collectionRef.doc(idx);

  const batch = firestore.batch();
  batch.delete(newDocRef);
  return await batch.commit();
};

export const convertCollectionsSnapshotToMap = (collections) => {
  const transformedCollection = collections.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      idx: doc.id
    }
  });

  return transformedCollection;
};

export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({
  prompt: 'select_account'
});
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;